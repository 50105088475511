import * as React from 'react';
import { useState, useEffect, useRef, useCallback } from "react";
import Menu from '@mui/icons-material/Menu';
import { convertActions } from './convertActions';
import Editor from './Editor';
import Button from '@mui/joy/Button';
import {
  editFeedBlock,
} from '../app/api/server';
import {
  useLocation,
} from "react-router-dom";
import ArrowBackIosNew from '@mui/icons-material/ArrowBackIosNew';
import CloseIcon from '@mui/icons-material/Close';

export default function FeedItem(props: any) {
  let extraContext;
  let converted;


  let location = useLocation();
  const [editingWindow, setEditingWindow] = useState(false);
  const [cursorPosition, setCursorPosition] = useState(0);
  const [userInfo, setUserInfo] = useState(props.user.userInfo);


  const copyToClipboard = (textToCopy: string) => {
    navigator.clipboard.writeText(textToCopy).then(function() {
      alert('Text successfully copied to clipboard!');
  }).catch(function(err) {
      console.error('Could not copy text: ', err);
  });
  }

  const showEdit = (direction: boolean) => {

 //   if (!editingWindow) { 
   // setTotalDoc(props.item.dat.content);
  
   props.setCurrentlyEditinger(props.item.id);
    setEditingWindow(direction);
  //  }
  }

  const closeEditor = () => {
   // showEdit(false);
  
    setEditingWindow(false);
  }

  const saveEditor = async (totaldoc: string) => {
    // save the content...
   
    const feeditemso = await editFeedBlock(props.user.user.uid, location.state.id, totaldoc, props.item.id, props.item.dat.ordering, props.item.dat.chapter);
   //JM-x updte
console.log('FEEDITEMSO=',feeditemso)
    props.setFeedItems(feeditemso.feed);

  setEditingWindow(false);
  // setRedoingSection(false);
  }

  const EditBox = (props:any) => {

    const cursorSet = (value:number) => {
      setCursorPosition(value);
    }
  
    const [totaldoc, setTotalDoc] = useState(props.content.item.dat.content);

    const handleChange = useCallback((content:any, delta: any) => {
      setTotalDoc(content);
    }, [props]);

    if (editingWindow && (props.content.currentlyEditing === props.content.item.id)) {
      return ( 
        <div style={{backgroundColor:'#aaaaaa'}}>
          <div style={{textAlign:'right'}}>
            <Button style={{margin:0,alignItems:'right'}} onClick={function() { closeEditor(); }}><CloseIcon /></Button>
          </div>
          <div>
            <Editor editingItem={props.content.item} value={totaldoc} species={props.content.project.species} handleChange={handleChange} extras={totaldoc} cursorSet={cursorSet} />
          </div>
          <div  style={{textAlign:'right'}}>
            <Button  style={{margin:0,alignItems:'right'}} onClick={function() { saveEditor(totaldoc); }}>Save</Button>
          </div>
        </div>
      )
    } else {
      if (!props.converted) {
      return (<div style={{marginLeft:'16px',fontSize:'1.1em'}}>{props.content.item.dat.content}</div>);
      } else {
        return (<div></div>)
      }
    }
  }

  const textareaRef = useRef(null);

  const ending = props.ending ? 'bold' : 'normal';
  if (props.item.dat.recordingExtraContext) {
    extraContext = props.item.dat.recordingExtraContext;
  extraContext.value = props.item.dat.content;
  converted = convertActions(extraContext.name,props.item.dat.content);
  }

  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const toggleMenu = () => {

    setIsMenuVisible(!isMenuVisible);
  }

      return (
  
        <tr>
        <td id={props.item.id} style={{width:'100%', fontWeight:'ending',whiteSpace: 'pre-wrap'}} onClick={() => { 
          if (!editingWindow) { 
            showEdit(true);
        }}}>
              {props.project.species === 'screenplay' && (
                              <div style={{borderRadius:'5px',backgroundColor:'#ffffff'}}>
              {!converted?.breakline && (
                     <div>
                     <div style={{marginLeft:'16px',fontSize:'1.1em'}} className="contextTitle">{converted?.text.toUpperCase()}</div>
                     <EditBox content={props} converted={true} />
                     </div> )}
              {converted?.breakline && (
                <div>
               {!editingWindow && ( <div style={{textAlign:'center',marginLeft:'16px',fontSize:'1.1em'}} className="contextTitle">{extraContext?.name.toUpperCase()}</div>)}
                <EditBox content={props} />
                </div>)}
                </div>
              )}
              {props.project.species === 'prose' && (
                              <div style={{borderRadius:'5px',backgroundColor:'#ffffff'}}>
                                <EditBox content={props} />
                              </div>

              )}
            </td>
                <td style={{backgroundColor:'#ffffff',flexDirection:'column',verticalAlign:'top'}}>

                <div className="dropdown">
  <button className="dropbtn" onClick={toggleMenu}><Menu /></button>
  {isMenuVisible && (
  <div className="dropdown-content" style={{backgroundColor:'ff0000'}}>
  <a onClick={() => { showEdit(true); setIsMenuVisible(!isMenuVisible); } } style={{color:'#000000',textDecoration:'none'}}>Edit</a>
  <a onClick={() => copyToClipboard(props.item.dat.content)} style={{color:'#000000',textDecoration:'none'}}>Copy</a>
<a onClick={() => props.deleteSection(props.item.id)} style={{color:'#000000',textDecoration:'none'}}>Delete</a>
<a onClick={() => props.redoSection()} style={{color:'#000000',textDecoration:'none'}}>Redo</a>
<a onClick={() => props.insertSection('above',props.index, props.item)} style={{color:'#000000',textDecoration:'none'}}>Insert Above</a>
<a onClick={() => props.insertSection('below', props.index + 1, props.item)} style={{color:'#000000',textDecoration:'none'}}>Insert Below</a>
  </div>
  )}
</div>

</td>
</tr>
);
}
import { useState, useEffect } from 'react';
import Button from '@mui/joy/Button';
import useAuth from "../hooks/useAuth";
import { ActionButtons } from './ActionButtons';
import Divider from '@mui/joy/Divider';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Edit from '@mui/icons-material/Edit';

export const ActionBlock = ({selectAction}:any) => {

    const [collapsedx, setCollapsedx] = useState(false);
    const [collapsedMenu, setCollapsedMenu] = useState(true);
    const [showActions, setShowActions] = useState(true);
    const [editingActions, setEditingActions] = useState(false);


    const toggleEditingActions = () => {
        editingActions ? setEditingActions(false) : setEditingActions(true);
        if (collapsedx) {
            setCollapsedx(false);
        }
       //setCollapsedx(!collapsedx);
    }

    const toggleShowActions = () => {
        setCollapsedx(!collapsedx);
      }

    const ActionsTitle = () => {
       
        return (
            <div style={{backgroundColor:'#cccccc',display:'flex', justifyContent:'space-between'}}>
                <div style={{paddingLeft:10}}>
                    <span style={{verticalAlign:'middle'}}>Actions</span>
                    {!collapsedx ?  (
        <ExpandLessIcon onClick={toggleShowActions} style={{verticalAlign:'middle',width:'2.5em',height:'2.5em',padding:0}} />
      ) : (
        <ExpandMoreIcon onClick={toggleShowActions} style={{verticalAlign:'middle',width:'2.5em',height:'2.5em',padding:0,margin:0}} />
      )}
    </div>
        {/*<div><Edit onClick={toggleEditingActions} style={{width:'2em',height:'2em',paddingRight:10,verticalAlign:'middle'}} /></div>*/}
        </div>)
    }



    const ActionsContent = () => {
  

        if (!collapsedx) {
    
   
            return (
                <div>
    
    <ActionButtons selectAction={selectAction} />
                </div>
            );

    
    
    
    
    } else {
        return (<div></div>);
    }
        }
    
        return (
            <div style={{backgroundColor:'#cccccc'}}>
                <ActionsTitle />
           <ActionsContent />
           </div>
        )
        };
    
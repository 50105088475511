import { useState, useEffect } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/joy/Button';
import TextField from '@mui/material/TextField';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import FormLabel from '@mui/joy/FormLabel';

import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import { deleteCharacter, createCharacter, getCharacters } from '../app/api/server';
import useAuth from "../hooks/useAuth";

import firebase from "firebase/compat/app";
import { doc, setDoc, getFirestore, addDoc, collection, getDocs, query, DocumentData, deleteDoc } from "firebase/firestore"; 
import { db } from '../firebase';
import { firebaseConfig } from '../config/firebaseconfig';

export function AddCharacter(props:any) {
    const setCharacters = props.setCharacters;
    const toggleEditingCharacters = props.toggleEditingCharacters;
    const projectid = props.projectid;
    const [collapsed, setCollapsed] = useState(true);

    const userid = useAuth();
    
    const formSubmit = async(e:any) => {
        //setCollapsed(false);
    
        e.preventDefault();
        
        createCharacter(e.target.title.value, userid?.user?.uid, projectid).then(() => {
            getCharacters(userid?.user?.uid, projectid).then((characters) => {
            setCharacters(characters as any);

          //  toggleEditingCharacters();
            });
       });
       
    }

    if (collapsed) {
        return (
            <div className="add-block" style={{backgroundColor:'#ffffff'}}>
                <div className="add-header" style={{padding:10,backgroundColor:'#fcfd91',color:'#323d43'}}>
                    Add a new character:
                </div>
                <div className="add-content">
                    <form method="post" onSubmit={formSubmit}>
                    <Table sx={{ }} aria-label="simple table">
                    <TableBody>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>
                        
                                    <Grid>
                                       <TextField id="title" placeholder="Type here" style={{width:'100%', backgroundColor:'#ffffff'}} />
                                    </Grid>
                                   
                             
                                </TableCell>


                                <TableCell align="left"><Button type="submit">add</Button></TableCell>
                                <TableCell align="right"><Button onClick={function() {toggleEditingCharacters(false);props.close();}}>X</Button></TableCell>
                            </TableRow>
                    

                            </TableBody>
                                </Table>  
                    </form>
                </div>
            </div>
        );
    } else {
        return (
            <div className="add-block">
                <div className="add-header" onClick={() => props.close()}>
                <Button>Add character</Button>
                </div>
            </div>
        );
    }
}
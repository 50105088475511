import * as React from 'react';
import { Navigate, useOutlet, useNavigate } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import HelpIcon from '@mui/icons-material/Help';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import MenuListComposition from '../MenuListComposition';
import useAuth from "../../hooks/useAuth";


interface HeaderProps {
  onDrawerToggle: () => void;
}

export default function Header(props: HeaderProps) {
  const { onDrawerToggle } = props;

  const navigate = useNavigate();
  const user = useAuth();

  const goList = () => {
    navigate('/list');
  };

  const userx = useAuth();

  const menuBackground = !user.user ? '#ffffff' : '#607cee';

  return (
    <React.Fragment>
      <AppBar color="secondary" position="sticky" elevation={0} style={{backgroundColor:menuBackground}}>
        {/*<Toolbar>
          <Grid container spacing={1} alignItems="center" style={{ flex: 1 }}>
          
            <Grid item xs={4}>
              <img src="/static/images/fictory-logo-2.png" alt="Fictoo" style={{width: '130px'}} />
            </Grid>
            <Grid  item xs={4}>
              spacer
            </Grid>
            <Grid item xs={2}> 
              <Tabs TabIndicatorProps={{
    style: {
      backgroundColor: "#ffffff"
    }
  }} value={0} textColor="inherit">
                <Tab label="List" onClick={goList} />
              </Tabs>
            </Grid>
            <Grid container item xs={2} direction="column"  display="flex" alignItems="flex-end">
              <MenuListComposition />
            </Grid>
          </Grid>
</Toolbar>*/}
<div className="navbarA" style={{backgroundColor:menuBackground}}>
  <div className="buttonsA">
    <button onClick={goList}>Projects</button>&nbsp;&nbsp;
    <MenuListComposition goToPricing={() => { navigate('/introduction#copyblock');}}/>
  </div>
</div>
      </AppBar>
      <AppBar
        component="div"
        color="primary"
        position="static"
        elevation={0}
        sx={{ zIndex: 0 }}
      >
      </AppBar>
      <AppBar component="div" position="static" elevation={0} sx={{ zIndex: 0 }}>
        
      </AppBar>
    </React.Fragment>
  );
}

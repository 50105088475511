import { useState, useEffect } from 'react';
import Button from '@mui/joy/Button';
import useAuth from "../hooks/useAuth";
import { getCharacters, deleteCharacter } from '../app/api/server';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';

export const CharacterButtons = ({characters, setCharacters, projectid, characterAction, manageCharacters}:any) => {

    const userid = useAuth();

    const selectCharacter = (character: any) => {
        const charblock = {name: character.dat.name, type: 'character', id: character.id}
        characterAction(charblock);
    }

    return (
       <Table>
            <TableBody>
                <TableRow style={{width:1}}>
                    <TableCell style={{padding:'8px',backgroundColor:'#cccccc'}}>
                        {characters.map((character:any) => (
                            <span style={{paddingRight:'5px'}} key={character.id}> 
                                <Button style={{ minHeight:'2em',margin:'0.1em' }} onClick={() => selectCharacter(character)}>{character.dat.name}</Button>
                            </span>
                        ))}
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
};

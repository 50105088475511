import { useState, useEffect } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/joy/Button';
import useAuth from "../hooks/useAuth";
import { getCharacters, deleteCharacter } from '../app/api/server';

export const CharacterList = (props:any) => {
    const characters = props.characters;
    const setCharacters = props.setCharacters;
    const projectid = props.projectid;

    const userid = useAuth();

    const deleteCharacters = (id: any) => {
        if (confirm("Are you sure you want to delete this character?") && userid?.user?.uid) {
            deleteCharacter(id, userid?.user?.uid, projectid).then(() => {
                getCharacters(userid?.user?.uid, projectid).then((characters) => {
                setCharacters(characters as any)});
                props.toggleEditingCharacters();
            });
        }
    }
    
    return (
        <Table  >
            <TableBody>
                {characters.map((character:any) => (
                    <TableRow key={`${character.id}`}>
                        <TableCell style={{fontWeight:'bold'}}>
                           {character.dat.name}
                        </TableCell>
                        <TableCell style={{textAlign:'right'}}>
                            <Button onClick={() => deleteCharacters(character.id)}>Delete</Button>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

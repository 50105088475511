import React from 'react';
import { useState, useEffect, useCallback, useRef } from "react";
import Button from '@mui/joy/Button';
import { convertActions } from './convertActions';
/* 
 * Simple editor component that takes placeholder text as a prop 
 */
//class Editor extends React.Component {

const CustomToolbar = () => (
  <div id="toolbar">
    <select
      className="ql-header"
      defaultValue={''}
      onChange={(e) => e.persist()}
    >
      <option value="1"></option>
      <option value="2"></option>
      <option selected></option>
    </select>
    <button className="ql-bold"></button>
    <button className="ql-italic"></button>
    <select className="ql-color">
      <option value="red"></option>
      <option value="green"></option>
      <option value="blue"></option>
      <option value="orange"></option>
      <option value="violet"></option>
      <option value="#d0d1d2"></option>
      <option selected></option>
    </select>
<Button>hi</Button>
  </div>
);

  function Editor(props) {
    const [modules, setModules] = useState(props.modules);

    const [cursor, setCursor] = useState(null);

    useEffect(() => {
      adjustHeight();
  }, []);  // Run adjustHeight once on mount for initial adjustment

  const adjustHeight = () => {
      const textarea = textareaRef.current;
      if (textarea) {
        setTimeout(() => {
          textarea.style.height = 'auto';
         textarea.style.height = textarea.scrollHeight + 'px';
      }, 0);
      }
  };



    const handleEditChange = (event, delta) => {
     // const cursorPos = inputRef.current.selectionStart;
     // setCursorPos(cursorPos);
   //  setTotalDoc(event.target.value);
      props.handleChange(event.target.value, delta);
      setCursor(event.target.selectionStart);
//console.log('CONTExxxNT=', content);
      
      //setRichTextValue(editor.getHTML());
    // setTotalDoc(content)
    // setTotalDoc(editor.getHTML());
    }

    useEffect(() => {
      if (totaldoc !== props.value) {
        setTotalDoc(props.value);
      }
    });

    useEffect(() => {
      extraInsert(props.insertion);
    }, [props.insertion])
       
    const [totaldoc, setTotalDoc] = useState('');

   /*
    useEffect(() => {
      const input = textareaRef.current;
      if (input) input.setSelectionRange(cursor, cursor);
   }, [textareaRef, cursor, totaldoc]);
   */

    // you can access the elements with itemsRef.current[n]

        const handleInsert = (content) => {
      // let contento = content;
      // !content && "HELLO WORLD!";
       var range = quill.current.editor.getSelection();
       let position = range ? range.index : 0;
       quill.current.editor.insertText(position, "RGREHEHRTE" );
     } 

    const extraInsert = (content) => {
     // var range = quill.current.editor.getSelection();
     // let position = range ? range.index : 0;
    //  console.log('EXTRA INSERT', quill.current.editor);
    //  console.log(position);
    //  console.log('content');
     
     // quill.current.editor.insertText(position, content );
    }

    
    const handleChange = (html) => {
      this.setState({ editorHtml: html });
    }
    
    /*handleThemeChange (newTheme) {
      if (newTheme === "core") newTheme = null;
      this.setState({ theme: newTheme })
    } */
    //render () {
 
    function convertToHTML(text) {
      const lines = text.split('\n');
      const htmlLines = lines.map(line => line.trim() ? line.trim() + '<br>' : '<br>');
      return htmlLines.join('');
    }

let ExtraContextRoot = props.editingItem.dat.recordingExtraContext?.name;
let extraHeader = ExtraContextRoot;
let converted = convertActions(extraHeader, props.value);
extraHeader = converted.text;
const textareaRef = useRef(null);
      return (
        <div>
        <div id="">
        {props.species === 'screenplay' && props.editingItem.dat.recordingExtraContext && (
              <div style={{textAlign:'center'}} className="contextTitle">{extraHeader.toUpperCase()}</div>
            )}
        <textarea className="editorTextArea"
          ref={textareaRef}
          defaultValue={totaldoc}
          ref={textareaRef}
          onChange={handleEditChange}

        />
         </div>
         </div>
       )
    //}
  }

  export default Editor;
import * as React from 'react';
import { Navigate, useOutlet, useNavigate } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import HelpIcon from '@mui/icons-material/Help';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import MenuListComposition from '../MenuListComposition';
import useAuth from "../../hooks/useAuth";
import firebase from "firebase/compat/app";
import { logout as logoutpage } from '../../app/api/server';
import useMediaQuery from '@mui/material/useMediaQuery';

interface HeaderProps {
  onDrawerToggle?: () => void;
}

export default function Header(props: HeaderProps) {
  const { onDrawerToggle } = props;

  const navigate = useNavigate();

  const goList = () => {
    navigate('/list');
  };

  const userx = useAuth();
  // const menuBackground = !userx.user ? '#d4e0d0' : '#ffffff';
  const menuBackground = '#eeeae7';

  const isLogoShowing = useMediaQuery('(min-width:600px)');

  return (
    <React.Fragment>
      <AppBar  position="sticky" elevation={0} style={{margin:0,borderBottom:"0px solid #888888", backgroundColor: menuBackground}}>
        <Toolbar>
          <Grid container spacing={1} alignItems="center" style={{ flex: 1 }}>
            
            <Grid item sx={{ display: { sm: 'block', xs: 'block' } }} xs={4}>
              <img src="/static/images/fictory-menu-clear-3.png" alt="logo" style={{width: '100%'}} />
            </Grid>

            <Grid xs={8} item display="flex" alignItems="flex-end">
              <div id="navigation">
                {!userx.user && isLogoShowing && (<div className="subnav-cta" id="login" style={{width:'200px',textAlign:'center'}}><a href="/login" style={{color:'#ffffff'}}>Get started for free</a></div>)}
                {!userx.user && isLogoShowing && (<div className="subnav"><a href="#pricing">Pricing</a></div>)}
                {userx.user && (<div className="subnav" id="list"><a href="/list">Dashboard</a></div>)}

                {!userx.user && (<div className="subnav" id="login"><a href="/login">Login</a></div>)}
                {userx.user && (<div style={{marginTop:'4px'}}><MenuListComposition goToPricing={() => { window.location.href = '#pricing'}} intro={true} /></div>)}
              </div>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>

    </React.Fragment>
  );
}

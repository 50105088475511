import { useState, useEffect } from 'react';
import Button from '@mui/joy/Button';
import useAuth from "../hooks/useAuth";
import { getCharacters, deleteCharacter } from '../app/api/server';
import { AddCharacter } from './AddCharacter';
import { CharacterList } from './CharacterList';
import { CharacterButtons } from './CharacterButtons';
import Divider from '@mui/joy/Divider';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Edit from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';

export const CharacterBlock = ({characters, isCharactersLoading, setCharacters, setEditingCharacters, editingCharacters, projectid, characterAction}:any) => {

    const userid = useAuth();
    const [collapsedx, setCollapsedx] = useState(characters.length === 0 ? false : true);
    const [collapsedMenu, setCollapsedMenu] = useState(true);
    const [showCharacters, setShowCharacters] = useState(true);

    /* useEffect(() => {
        getCharacters(userid?.user?.uid, projectid).then((characters) => {

            setCharacters(characters as any);
        });
    }, []); */

    useEffect(() => {
    toggleEditingCharacters(false);
    }, [characters]);

    const toggleEditingCharacters = (direction = false) => {
         setEditingCharacters(direction);
        if (collapsedx || characters.length > 0) {
            setCollapsedx(false);
        }
       // setCollapsedx(!collapsedx);
    }

    const toggleShowCharacters = () => {
        setCollapsedx(!collapsedx);
      }

    const close = () => {
        setCollapsedx(true);
    }

    const CharactersTitle = () => {
       
        return (
            <div style={{backgroundColor:'#cccccc',display:'flex', justifyContent:'space-between'}}>
                <div style={{paddingLeft:10}}>
                    <span style={{verticalAlign:'middle'}}>Characters</span>
                    {!collapsedx ?  (
        <ExpandLessIcon onClick={toggleShowCharacters} style={{verticalAlign:'middle',width:'2.5em',height:'2.5em',padding:0}} />
      ) : (
        <ExpandMoreIcon onClick={toggleShowCharacters} style={{verticalAlign:'middle',width:'2.5em',height:'2.5em',padding:0}} />
      )}
    </div>
        <div>
            

        { !editingCharacters && (  <Button onClick={() => toggleEditingCharacters(true)}
        style={{marginTop:'5px',marginRight:10,verticalAlign:'middle'}}
  startDecorator={<Edit />}
>
  Add / Edit
</Button>)}
{ editingCharacters && (
     <Button onClick={() => toggleEditingCharacters(false)}
        style={{marginTop:'5px',marginRight:10,verticalAlign:'middle'}}
  startDecorator={<Edit />}
>
  Close
</Button>)}
        
            </div>
        </div>)
    }

    const toggleMenu = () => {
        console.log('toggleMenu')
        setCollapsedMenu(collapsedMenu);
    }


    const CharacterContent = () => {
  

    if (!collapsedx) {
if (editingCharacters || characters.length ===0) {
    return (
        <div style={{position:'sticky'}}>
      
            {/*<Button onClick={close} style={{marginBottom:'15px'}}>CLOSE</Button>*/}
 
        {characters.length ===0 && !isCharactersLoading && (<div style={{fontSize:'1.2em',fontWeight:'bold',margin:'10px'}}>Now let's add a new character to your screenplay:</div>)}
            <AddCharacter setCharacters={setCharacters} projectid={projectid} close={close} toggleEditingCharacters={toggleEditingCharacters} />
       
            <div></div>
            <CharacterList characters={characters} toggleEditingCharacters={toggleEditingCharacters} setCharacters={setCharacters} projectid={projectid} />
            </div>
    );
    } else {
        return (
            <div>
        <CharacterButtons characters={characters} characterAction={characterAction} setCharacters={setCharacters} projectid={projectid} />
            </div>
        );
    }




} else {
    return (<div></div>);
}
    }

    return (
        <div>
            <CharactersTitle />
       <CharacterContent />
       </div>
    )
    };

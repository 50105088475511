
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import TwitterIcon from '@mui/icons-material/Twitter';
import { Twitter } from '@mui/icons-material';

export default function Copyright() {
    return (
      <div style={{padding:'25px',backgroundColor:'#eeeeee',color:'#ffffff'}}>
        <Grid container  direction="row" spacing={0} alignItems="center">
            <Grid item xs={6}>
             
        <div>
          <div>
            <div className="footerLogo"><img src="/static/images/fictory-menu-clear-3.png" alt="logo" style={{width: '150px'}} /></div>
            <div className="footerSubtext" style={{color:'#000000',margin:'20px'}}>1.2.74daIt's time to talk writing....</div>
          </div>
          <div><TwitterIcon style={{fontSize:'2em',marginLeft:'20px',color:'#000000'}} fontSize="large" /></div>
        </div>
        </Grid>
        <Grid item xs={6}>
          <div>
            <div className="legal" style={{color:'#000000'}}>
              {/*<div>Terms & conditions</div>
              <div>Privacy Policy</div>*/}
            </div>
          </div>
          <div className="footerCopyright">
            <Typography variant="body2" color="text.secondary" align="right">
              {'Copyright © '}
              <Link color="inherit" href="">
                Fictoo
              </Link>{' '}
              {new Date().getFullYear()}.
            </Typography>
          </div>
        </Grid>
        </Grid>
      </div>
    );
  }
  
import { Navigate, useOutlet, useNavigate } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import useAuth from "../hooks/useAuth";
import Navigator from '../components/paperbase/Navigator';
import Content from '../components/paperbase/Content';
import Header from '../components/paperbase/Header';
import firebase from "firebase/compat/app";
import { logout as logoutpage } from '../app/api/server';
import Button from '@mui/joy/Button';
import { deepmerge } from '@mui/utils';
import * as React from 'react';
import { experimental_extendTheme as extendMuiTheme, ThemeProvider, createTheme } from '@mui/material/styles';
import colors from '@mui/joy/colors';
import { doc, deleteDoc, updateDoc, setDoc, getFirestore, addDoc, onSnapshot, collection, getDocs, orderBy, query, DocumentData, getDoc, where } from "firebase/firestore"; 
import useMediaQuery from '@mui/material/useMediaQuery';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Copyright from '../components/Copyright';
import { getUserInfo } from '../app/api/server';
import AuthContext from "../Auth/AuthContext";
import UserContext from "../UserContext";
import { db } from "../firebase";
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { firebaseConfig } from "../config/firebaseconfig";
import {
  extendTheme as extendJoyTheme,
  CssVarsProvider,
} from '@mui/joy/styles';

// You can put this to any file that's included in your tsconfig
import type { PaletteRange } from '@mui/joy/styles';

declare module '@mui/joy/styles' {
  interface ColorPalettePropOverrides {
    // apply to all Joy UI components that support `color` prop
    secondary: true;
    primary: true;
  }

  interface Palette {
    // this will make the node `secondary` configurable in `extendTheme`
    // and add `secondary` to the theme's palette.
    secondary: PaletteRange;
    primary: PaletteRange;
  }
}

const muiTheme = extendMuiTheme({
  // This is required to point to `var(--joy-*)` because we are using
  // `CssVarsProvider` from Joy UI.
  components: {
    MuiInputBase : {
      styleOverrides: {
        root: {
          height: '3rem',
        },
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          padding: '0.5rem',
          borderTop:'1px solid #ccc',
        },
      },
    },
  },
});

// You can use your own `deepmerge` function.
// joyTheme will deeply merge to muiTheme.

export const ProtectedLayout = () => {
  const outlet = useOutlet();
  const navigate = useNavigate();
  const userx = useAuth();

  // State to hold the fetched data
  const [userInfo, setUserInfo] = useState({value:null});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const userInfox = React.useContext(UserContext) as any;
  

///////// paperbase


  // const drawerWidth = 256;
  const drawerWidth = 0;


    const [mobileOpen, setMobileOpen] = React.useState(false);
    // const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
    

    const handleDrawerToggle = () => {
      setMobileOpen(!mobileOpen);
    };


  ///////////////////

  // disable if we want the user to be able to use without a subscription
  /*
  if (userInfox.userInfo?.subscriptions?.length === 0) {
    console.log('NO SUBSCRIPTIONS')
   // navigate('/');
  }
*/
  //const planOK = userInfox.userInfo?.subscriptions?.length > 0;
  const planOK = true;

    return (
        <Box sx={{ display: 'flex', minHeight: '100vh' }}>
          <CssBaseline />
          <Box
            component="nav"
            sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          >
            {/*isSmUp ? null : (
              <Navigator
                PaperProps={{ style: { width: drawerWidth } }}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
              />
            )*/}
            {/*
            <Navigator
              PaperProps={{ style: { width: drawerWidth } }}
              sx={{ display: { sm: 'block', xs: 'none' } }}
          />*/}
          </Box>

          <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
            <Header onDrawerToggle={handleDrawerToggle} />
            <Box component="main" sx={{ flex: 1, py: 0, px: 0, bgcolor: '#edeae7' }}>
            
              {userx.user && planOK && outlet}
            </Box>

              {/*<Copyright />*/}


          </Box>
    
        </Box>


    );
}
import { useState, useEffect } from 'react';
// import { getUser } from './auth.js'
import AuthContext from './AuthContext'

export interface AuthProviderInterface {
    Component?: React.FunctionComponent;
    children: React.ReactNode;
  }


export interface UserData {
    currentUser: string
}



export const AuthProvider = ({ children }: AuthProviderInterface) => {

const [currentUser, setCurrentUser] = useState<string>('');

    useEffect(() => {
    const currentUser = 'hello'
    setCurrentUser(currentUser);
}, []);

  
    return (
      <AuthContext.Provider value={currentUser}>{children}</AuthContext.Provider>
    );
  };
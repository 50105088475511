import UserContext from "../UserContext";
import React from 'react'
import { endpoint } from "../config/firebaseconfig";

export const PortalButton = () => {
  const userContext: any = React.useContext(UserContext);

    const handleAccounts = () => {
      fetch(`${endpoint}create-portal-session`, {
                headers: {
                    "Content-Type": "application/json"
                  },
                method: 'post',
                body: JSON.stringify({
                    path: 'list',
                    //session_id: ,
                    customer_id: userContext.userInfo.value.stripeId,
                }),
            })
            .then(res => res.json())
            .then(
              (result) => {
                window.location.href = result.url;
               // setIsLoaded(true);
               // setItems(result);
              },
              // Note: it's important to handle errors here
              // instead of a catch() block so that we don't swallow
              // exceptions from actual bugs in components.
              (error) => {
                console.log(error);
              }
            )
        }
        return (
        <button onClick={handleAccounts}>Account and Billing</button>
        )
}

import { doc, deleteDoc, updateDoc, setDoc, getFirestore, addDoc, collection, getDocs, orderBy, query, DocumentData, getDoc, where, onSnapshot } from "firebase/firestore"; 
import { useState, useEffect, useContext } from "react";
import firebase from "firebase/compat/app";
import { db } from "../../firebase";
import getStripe from '../../lib/getStripe';
import { getFunctions, httpsCallable } from 'firebase/functions'
import { SubscriptionsOutlined } from "@mui/icons-material";

export const checkoutProduct = (productid: string) => {
    getProducts().then((products) => {

       //
       // getCheckout(firstprod);
    })
}

export const updateSubscriptionsAndAllowances = async (userid: string, subscriptions:any) => {


  const allowancesRef = doc(db, "user_allowances", userid);
  const allowancesDocSnap = await getDoc(allowancesRef);

  let freeplan: any = {
    minutes: 10,
    projects: 2,
    description: 'Free Plan',
    name: 'freeplan1'
  };

  let freeAllowances: any = {
    current_end: 2145916800,
    current_start: 1709339763,
    minutes_count: 0, // minutes used
    plan: 'freeplan',
    project_count: 0,
    minutes_allowed: freeplan.minutes,
    projects_allowed: freeplan.projects,
    usage_expires: 2145916800,
  };

  if (subscriptions.subscriptions.length === 0) {
    const freePlanRef = doc(db, "plans", 'freeplan');
    const docSnapFreePlan = await getDoc(freePlanRef);
    if (docSnapFreePlan.exists()) {
      freeplan = docSnapFreePlan.data();
    }

    if (allowancesDocSnap.exists()) {
      // We have a free plan usage already so we are all good.
      const currentUsages = allowancesDocSnap.data();
      return currentUsages;
    } else {
      await setDoc(doc(db, "user_allowances", userid), freeAllowances);

      return freeAllowances;
    }
  } else {
    // we have a subscription, so let's get the plan details

    const productid = subscriptions.subscriptions[0].plan.product;

    const plansRef = doc(db, "plans", productid);
    const docSnapPlan = await getDoc(plansRef);
    let planLimits = freeplan;

   
    let basicAllowances: any = {
      current_end: 2145916800,
      current_start: 1709339763,
      minutes_count: 0, // minutes used
      plan: 'freeplan',
      project_count: 0,
      minutes_allowed: freeplan.minutes,
      projects_allowed: freeplan.projects,
      usage_expires: 2145916800,
    };

    if (docSnapPlan.exists()) {
 
      planLimits = docSnapPlan.data();
      basicAllowances.minutes_allowed = planLimits.minutes;
      basicAllowances.projects_allowed = planLimits.projects;
      basicAllowances.current_end = subscriptions.subscriptions[0].current_period_end;
      basicAllowances.current_start = subscriptions.subscriptions[0].current_period_start;
      basicAllowances.usage_expires = subscriptions.subscriptions[0].current_period_end;
      basicAllowances.plan = productid;
    }

    if (allowancesDocSnap.exists()) {

      // so I have allowances, do they match my plan?
      const currentUsages = allowancesDocSnap.data();
      if (currentUsages.plan === productid) {

        return currentUsages;
      } else {

        // we have a new plan, let's reset the usage
        basicAllowances.project_count = currentUsages.project_count;
        basicAllowances.minutes_count = currentUsages.minutes_count;
        basicAllowances.minutes_allowed = parseInt(currentUsages.minutes_allowed) + parseInt(planLimits.minutes);
        basicAllowances.projects_allowed = parseInt(currentUsages.projects_allowed) + parseInt(planLimits.projects);
        await setDoc(doc(db, "user_allowances", userid), basicAllowances);
    
        return basicAllowances;
      }
    } else {
       await setDoc(doc(db, "user_allowances", userid), basicAllowances);
    
      return basicAllowances;
    }
  }
}

export const fetchUsage = async (userid: string) => {
  const allowancesRef = doc(db, "user_allowances", userid);
  const allowancesDocSnap = await getDoc(allowancesRef);
  if (allowancesDocSnap.exists()) {
    return allowancesDocSnap.data();
  } else {
    return null;
  }
  
}


export const getProducts = async () => {
// create a query object
const q = query(
  collection(db, 'products'), 
  where('active', '==', true)
);

const querySnapshot = await getDocs(q);

// for each product, get the product price info
const productsPromises = querySnapshot.docs.map(async (productDoc) => {
  let productInfo = productDoc.data();

  // fetch prices subcollection per product
  const pricesCollection = collection(productDoc.ref, 'prices');
  const priceQuerySnapshot = await getDocs(pricesCollection);

  // assume there is only one price per product
  const priceDoc = priceQuerySnapshot.docs[0];
  productInfo['priceId'] = priceDoc.id;
  productInfo['priceInfo'] = priceDoc.data();
  return productInfo;
});

// 'products' is an array of products (including price info)
const products = await Promise.all(productsPromises);
return products;
}

export const subscribe = async (product:any, currentUser:any) => {
  let checkoutSessionData = {
    price: product.priceId, // price ID from products fetch
    success_url: window.location.origin, // can set this to a custom page
    cancel_url: window.location.origin   // can set this to a custom page
  };

  // if payment is a one-time payment (as opposed to subscription)
  // append mode: 'payment' to the checkout session data
  // if (isOneTime) {
  //    checkoutSessionData['mode'] = 'payment';
  // }

  const docRef = await addDoc(
    // currentUser is provided by firebase, via getAuth().currentUser
    collection(db, `users/${currentUser.uid}/checkout_sessions/`), 
    checkoutSessionData
  )


  onSnapshot(docRef, (snap) => {
        const snapdata = snap.data();
        if (snapdata?.url) {
          // We have a Stripe Checkout URL, let's redirect.
          window.location.assign(snapdata.url);
      }
  })
}

export const goToPortal = async (product:any, currentUser:any) => {

  // just trying to get webhook to set portal link
// after initializing firebase
const functions = getFunctions();

const functionref = httpsCallable(functions, 'ext-firestore-stripe-payments-createPortalLink');

functionref({
    returnurl: `${window.location.origin}/`,
    locale: "auto",
  })
  .then((result) => {
    const data = result.data;
  });
}
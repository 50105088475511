import { useState, useEffect } from 'react';
import Button from '@mui/joy/Button';
import useAuth from "../hooks/useAuth";
import { getCharacters, deleteCharacter } from '../app/api/server';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

export const ActionButtons = ({selectAction}:any) => {

    const userid = useAuth();

    return (
       <Table>
            <TableBody>
                <TableRow style={{width:1}}>
                    <TableCell style={{padding:'8px'}}>

                    <span style={{paddingRight:'5px'}}>
                        <Button style={{ minHeight:'2em',margin:'0.1em' }} onClick={() => selectAction({type:'action',name:'actionline'})}>
                            Action
                        </Button>
                    </span>
                    
                    <span style={{paddingRight:'5px'}}>
                        <Button style={{ minHeight:'2em',margin:'0.1em' }} onClick={() => selectAction({type:'action',name:'intday'})}>
                            INT. DAY
                        </Button>
                    </span>
                    
                    <span style={{paddingRight:'5px'}}>
                        <Button style={{ minHeight:'2em',margin:'0.1em' }} onClick={() => selectAction({type:'action',name:'intnight'})}>
                            INT. NIGHT
                        </Button>
                    </span>
                    
                    <span style={{paddingRight:'5px'}}>
                        <Button style={{ minHeight:'2em',margin:'0.1em' }} onClick={() => selectAction({type:'action',name:'extday'})}>
                            EXT. DAY
                        </Button>
                    </span>
                    
                    <span style={{paddingRight:'5px'}}>
                        <Button style={{ minHeight:'2em',margin:'0.1em' }} onClick={() => selectAction({type:'action',name:'extnight'})}>
                            EXT. NIGHT
                        </Button>
                    </span>            

                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
};

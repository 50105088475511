import React from 'react';
import {
  Navigate, useNavigate
} from "react-router-dom";
import firebase from "firebase/compat/app";
import { doc, setDoc, getFirestore, addDoc, collection } from "firebase/firestore"; 
import logo from './logo.svg';
import '../../App.css';
import Copyright from '../Copyright';
import IntroHeader from '../IntroHeader/IntroHeader';

import { useState, useEffect } from "react";
import StyledFirebaseAuth from '../../features/auth/StyledFirebaseAuth';
import { writeUser } from '../../app/api/server';
import { firebaseConfig } from '../../config/firebaseconfig';

const ThemeContext = React.createContext('light');
const app = firebase.initializeApp(firebaseConfig);
export const db = () => {
  return getFirestore(app);
}

function Login() {

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [content, setContent] = useState('placeholder');
    const navigate = useNavigate();

    var uiConfig = {
    callbacks: {
        signInSuccessWithAuthResult:  function(authResult: any, redirectUrl: any) {
        const uid = authResult.user.multiFactor.user.uid;
        const email = authResult.user.multiFactor.user.email;
        const name = authResult.user.multiFactor.user.displayName;
        writeUser(authResult.user.multiFactor.user.email, authResult.user.multiFactor.user.displayName, authResult.user.multiFactor.user.uid );
        navigate('/list');
        return false;
        },
    },
    // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
    signInFlow: 'popup',
   //signInSuccessUrl: '/list',
    signInOptions: [
        // Leave the lines as is for the providers you want to offer your users.
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
    ],
    // Terms of service url.
    tosUrl: '<your-tos-url>',
    // Privacy policy url.
    privacyPolicyUrl: '<your-privacy-policy-url>'
    };

  const authUser = Object.keys(window.localStorage)
  .filter(item => item.startsWith('firebase:authUser'))[0];
  return (
    <div className="App">
      <IntroHeader />
      <div id="subtitle" style={{fontSize:'2em',textAlign:'center',maxWidth:'2000px',margin:'1em'}}>Login</div>
    <ThemeContext.Provider value="dark">
    {!authUser ? (
    <div>
        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
        <div id="firebaseui-auth-container"></div>
    </div> 
    ) : (
    <div></div>
    )}
      </ThemeContext.Provider>
      <Copyright />
   </div>
  )
}

export default Login;

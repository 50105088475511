// @ts-nocheck
import React from 'react';
import { Row, Col, Badge } from "antd";
import { useState, useEffect, useCallback, useMemo, useContext, useRef, useImperativeHandle, forwardRef } from "react";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { exportFile } from 'fs-browsers';
import { useWhisper } from '../../libraries/use-whisper-live/useWhisper'
import ProgressBar from "@ramonak/react-progress-bar";
import Grid from '@mui/joy/Grid';
import Box from '@mui/joy/Box';
import firebase from "firebase/compat/app";
import { doc, setDoc, getFirestore, addDoc, collection, updateDoc, getDoc, query, getDocs, DocumentData, deleteDoc, orderBy, Transaction } from "firebase/firestore"; 
import logo from './logo.svg';
import Divider from '@mui/joy/Divider';
import Table from '@mui/material/Table';
import Switch from '@mui/joy/Switch';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import Button from '@mui/joy/Button';
import Stack from '@mui/material/Stack';
import FileDownload from '@mui/icons-material/FileDownload';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import KeyboardVoice from '@mui/icons-material/KeyboardVoice';
// import CircularProgress from '@mui/joy/CircularProgress';
import CircularProgress from '@mui/material/CircularProgress';
import UserContext from "../../UserContext";
import SpeechRecognition from "./SpeechRecognition";

import Edit from '@mui/icons-material/Edit';
import TextField from '@mui/material/TextField';
import ArrowBackIosNew from '@mui/icons-material/ArrowBackIosNew';
import { ScreenRecording } from '../../features/audioTest/ScreenRecording';
import Editor from '../Editor';
import Feed from '../Feed';
import {
  useLocation,
  useNavigate,
  Navigate
} from "react-router-dom";
import '../../App.css';
import { AddCharacter } from '../AddCharacter';
import { CharacterList } from '../CharacterList';
import { CharacterBlock } from '../CharacterBlock';
import { ActionBlock } from '../ActionBlock';
import {
  getCharacters,
  getProject,
  saveFeedBlock,
  getFeedBlocks,
  editFeedBlock,
  deleteFeedBlock,
  getChapters,
  editProjectTitle,
} from '../../app/api/server';
import useAuth from "../../hooks/useAuth";
// import { initializeApp } from 'firebase/app';
import StyledFirebaseAuth from '../../features/auth/StyledFirebaseAuth';
import { firebaseConfig } from '../../config/firebaseconfig';

import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import ModalClose from '@mui/joy/ModalClose';
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { endpoint } from "../../config/firebaseconfig";

import Transcription from './Transcription';


const ThemeContext = React.createContext('light');
import { db } from '../../firebase';

const maxTime = 60;


const checkUsageRemaining = (userInfo) => {

  if (userInfo?.subs && userInfo?.subs.usage_expires > (Date.now() / 1000) && userInfo.subs.minutes_count <  userInfo.subs.minutes_allowed) {
    const usage = (userInfo.subs.minutes_allowed - userInfo.subs.minutes_count);  
    return usage;
  } else {
    return 0;
  }
}

const FeedArea = ({redoSection, insertSection, feedItems, editSection, deleteSection, location, project, user, setFeedItems}) => {
  return (
    <div>
     
  <Feed user={user} setFeedItems={setFeedItems} project={project} insertSection={insertSection}  feedItems={feedItems} editSection={(item: any) => { editSection(item) }} deleteSection={(item:any) => { deleteSection(item) }} redoSection={(item:any) => { redoSection(item)}} />
  </div>
  )
}

const ActionBar = ({
  setContent, setCharacters,
  selectAction, characters, characterAction, location, editingCharacters, setEditingCharacters}) => {
  return (
  <div>
          <div style={{border:'1px solid #999999', backgroundColor:'#fdfdfd'}}>
             <CharacterBlock editingCharacters={editingCharacters} setEditingCharacters={setEditingCharacters} characters={characters} setCharacters={setCharacters} characterAction={characterAction} projectid={location.state.id} />
          </div>
          <div style={{border:'1px solid #999999', backgroundColor:'#fdfdfd'}}>
            <ActionBlock selectAction={selectAction} />
          </div>
        </div>
  )
}

const RecordingButton = ({ open, recordingInProgress, setStoppedRecordingFromButton, usageRemaining }) => {
  const [flash, setFlash] = useState(false);

  
    const [shouldFlash, setShouldFlash] = useState(false);

  const recordingTitle = recordingInProgress ? 'Recording' : 'Record';

  useEffect(() => {
    let interval;
    if (recordingInProgress) {
      interval = setInterval(() => {
        setFlash((prev) => !prev);
      }, 500); // 500ms interval for flash
    } else {
      clearInterval(interval);
      setFlash(false); // Reset to original state
    }
    return () => {
      clearInterval(interval); // Cleanup interval on unmount or when shouldFlash changes
    };
  }, [recordingInProgress]);

  const toggle = () => {
    setShouldFlash((prev) => !prev);
    if (recordingInProgress) {
     setStoppedRecordingFromButton(true);
    } else {
      open();
    }
  }

  return (
    <Button onClick={() => toggle()}
        style={{backgroundColor:flash ? 'red' : '#ed5e5b',border:'1px solid #aaaaaa',verticalAlign:'middle'}}
  startDecorator={<KeyboardVoice />}
>
 {recordingTitle}
</Button>
  );

};


const ButtonBar = ({
  startRecordingProcess,
  stopRecordingProcess,
  startRecordingProcessSingle,
  stopRecordingProcessSingle,
  pauseRecordingProcess,
  recordingModalNewOpen,
  recordingInProgress,
  recordingSingle,
  aiPolish,
  setAiPolish,
  editingPhase,
  feedItems,
  isLoadingFeed,
  editingCharacters,
  setStoppedRecordingFromButton,
  usageRemaining
}) => {

  return (
    <div>
{!editingPhase &&(<Row>
  <Col span="12" style={{ textAlign: "center",margin:'0.5em' }}>
  {/*AI: <Switch 
  checked={aiPolish}
  onChange={() => setAiPolish(!aiPolish)}
  />
  <Button onClick={() => recordingModalOpen()}>TestOverlay</Button>*/}
  {feedItems.length === 0 && !isLoadingFeed && !editingCharacters && (
    <div style={{margin:'10px',fontWeight:'bold',fontSize:'1.2em'}}>Hit record to start dictating!</div>
  )}
<RecordingButton style={{marginTop:'0px',position:'fixed',zIndex:900,top:'100px'}} open={() => recordingModalNewOpen()} recordingInProgress={recordingInProgress} setStoppedRecordingFromButton={setStoppedRecordingFromButton} usageRemaining={usageRemaining} />
  {/*<br />
  <Button onMouseDown={startRecordingProcess} onMouseUp={stopRecordingProcess} style={{ marginRight: "15px" }}>AAAA<TouchAppIcon style={{fontSize:'400%',margin:'0.2em'}} /></Button>
  <span style={{fontWeight:'bold'}}>&nbsp;OR&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
    {recordingInProgress === false && recordingSingle === false && (
      <Button onMouseDown={startRecordingProcessSingle}><MicIcon style={{fontSize:'400%',margin:'0.2em'}}/></Button>
    )}
    {recordingInProgress === true && recordingSingle === true && (
      <Button  style={{backgroundColor:'#eb3734'}} onClick={stopRecordingProcessSingle}><MicOffIcon style={{fontSize:'400%',margin:'0.2em'}} /></Button>
    )}
    <button onClick={() => pauseRecordingProcess()}>Pause</button>
    */}
        </Col>
    </Row>)}
    </div>
  )     
}

const FilterBar = ({setFilter, filter}) => {
  return (
    <div>
      <Row>
  <Col span="12" style={{ textAlign: "left" }}>
  <Button onClick={() => setFilter('all')}>All</Button>
  <Button onClick={() => setFilter('characters')}>Characters</Button>
  <Button onClick={() => setFilter('actions')}>Actions</Button>
  <Button onClick={() => setFilter('locations')}>Locations</Button>
  <Button onClick={() => setFilter('dialogue')}>Dialogue</Button>
  <Button onClick={() => setFilter('narration')}>Narration</Button>
  </Col>
  </Row>
    </div>
  )
}

const setFilter = (filter) => {
  
}

const RecordingModalNew = ({
  cancelNewRecording,
  setOpen,
  open,
  recordingInProgress,
  stopRecordingProcess,
  tryRecordingAgain,
  pauseRecordingNewProcess,
  startFromModalNewRecording,
  stopRecordingNewModal,
  recordingPaused,
  setRecordingPaused,
  processingRecording,
  recordingExtraContext,
  transcriptDone,
  setTranscriptDone,
  setProcessingRecording,
  transcriptJM,
  setTranscriptJM,
  totalTranscript,
  runningTotalText,
  secondStageInProgress,
  retryProcessing,
  cancelProcessing,
  acceptProcessing,
  stoppedRecordingFromButton,
  setStoppedRecordingFromButton,
  recordingModalNewOpen,
speechRef,
setRecordingInProgress,
setSecondStageInProgress,
setShouldListen,
elapsed,
setElapsed,
usageRemaining,
emergencyStop,
setEmergencyStop,
project,
setSpinnerWaiter,
spinnerWaiter,
characterOption,
actionOption
}) => {

    const liveText = useRef();  // Create a ref

    const [counter, setCounter] = useState(0);
    const [barColor, setBarColor] = useState('#94a7f8');
    const [barProgress, setBarProgress] = useState(0);


   
  
    const maxAccountRemaining = usageRemaining * 60;
    // const sessionRecTimeLimit = 600;
    const sessionRecTimeLimit = 50;
    const maxThisSession = maxAccountRemaining > sessionRecTimeLimit ? sessionRecTimeLimit : maxAccountRemaining;

    const isUnmountingRef = useRef({ value: false });



  /*
  useEffect(() => {


    const progInterval = 2000;
    // const progInterval = Math.ceil(secondsLeft / 10) * 1000;

    // how to only start on recording and reset on stop recording
    if (recordingInProgress) {

      if (counter >= maxThisSession) {
   
        setEmergencyStop(true);
       

      //  stopRecordingNewModal();
      }
      if (((counter / maxThisSession) * 100) > 70) {
        setBarColor('#ed5e5b');
      }

      if (counter < maxThisSession && !recordingPaused) {
        setTimeout(() => {
       
          setCounter(counter + (progInterval / 1000));
          setBarProgress(parseInt((counter / maxThisSession) * 100));
       
          setElapsed(elapsed + 2);
        }, progInterval);
      }
    }
    if (!recordingInProgress) {
      setCounter(0);
      setBarColor('#94a7f8');
    }
}, [counter, recordingInProgress, recordingPaused]);
*/

    useEffect(() => {
     if (transcriptJM !== '') {
        stopRecordingNewModal();
      }
      if (!processingRecording && !recordingInProgress && transcriptJM) {   
     // const scrollableDiv = liveText.current;
     // scrollableDiv.scrollTop = scrollableDiv.scrollHeight;
    //setTranscriptDone(transcriptDone + transcriptJM);
    }
    }, [transcriptJM]); 
    
    
    

let extraHeader = '';

if (recordingExtraContext && recordingExtraContext.name) {
    extraHeader = recordingExtraContext.name;
    if (recordingExtraContext.type === 'action') {
      if (recordingExtraContext.name === 'intday') {
        extraHeader = 'INT.  - DAY';
      }
      if (recordingExtraContext.name === 'extday') {
        extraHeader = 'EXT. - DAY';
      }
      if (recordingExtraContext.name === 'intnight') {
        extraHeader = 'INT. - NIGHT';
      }
      if (recordingExtraContext.name === 'extnight') {
        extraHeader = 'EXT. - NIGHT';
      }
      if (recordingExtraContext.name === 'actionline') {
        extraHeader = '';
      }
    }

   // if (recordingExtraContext.type === 'character') {
    //  extraHeader = `${recordingExtraContext.name}:`;
   // }
  }
  const opendisplay = open === true ? 'block' : 'none';

  const liveTextHeight = '300px';

const pear = true;

  return (
    <div style={{flex:1,display: 'flex', flexDirection:'column',height:'100%',}}>
  
  {spinnerWaiter && !recordingInProgress && (
        <div style={{position:'fixed',top:'60%',left:'50%',transform:'translate(-50%, -50%)',zIndex:900}}>
          <CircularProgress />
        </div>
      )}

     {(recordingInProgress) && (
      <div id="reworkedRecording" style={{backgroundColor:'#ffffff',flex:1,display:'flex',flexDirection:'column',height:'100%',padding:'20px'}}>
    
  {/*  <div style={{width:'100%',backgroundColor:'#cccccc',height:'15px'}}>

        <ProgressBar 
completed={barProgress}
bgColor={barColor}
baseBgColor="#cccccc"
labelColor="#ffffff"
/>  
     </div> */}
          <div>
            <div ref={liveText}  style={{ overflow:'scroll',}}>
            {extraHeader && (<div style={{marginTop:extraHeader ? '0px' : '20px',width:'100%',textAlign:'center'}}>
              <b> { extraHeader }</b>
            </div>)}
           
     { (<SpeechRecognition ref={speechRef} setSpinnerWaiter={setSpinnerWaiter} characterOption={characterOption} actionOption={actionOption} project={project} setTranscriptJM={setTranscriptJM} stopRecordingNewModal={stopRecordingNewModal} stoppedRecordingFromButton={stoppedRecordingFromButton} setStoppedRecordingFromButton={setStoppedRecordingFromButton} recordingInProgress={recordingInProgress} setRecordingInProgress={setRecordingInProgress} setShouldListen={setShouldListen} setProcessingRecording={setProcessingRecording} setSecondStageInProgress={setSecondStageInProgress} emergencyStop={emergencyStop} setEmergencyStop={setEmergencyStop} recordingPaused={recordingPaused} setRecordingPaused={setRecordingPaused} spinnerWaiter={spinnerWaiter}  />)}
     {/*(<Transcription />)*/}
         <div id="transcript" style={{margin:'20px',whiteSpace: 'pre-wrap'}}>
          {!transcriptJM === '' && (<div>Transcript will appear here...</div>)}
   
      {transcriptJM}
         </div>
            </div>
            <div>
              {/*<Grid container spacing={2} sx={{ flexGrow: 1}}>
                
                <Grid sx={{border:'1px solid #cccccc;'}} xs={4} md={4}>
                  <div>
                    <Button onClick={() => {
                      cancelNewRecording();
                      return setOpen(false);
                    }}>CLOSE</Button>
                  </div>
                </Grid>
                <Grid xs={8} md={8} sx={{border:'1px solid #cccccc;'}}>
                {recordingPaused && (<div><Button style={{margin:'1em'}} onClick={() => startFromModalNewRecording()}>RESUME</Button></div>)}
                {!recordingPaused && (
                  <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', justifyContent:"flex-end"  }}>
                    <Button sx={{marginRight:'10px;'}} onClick={() => pauseRecordingNewProcess()}>PAUSE</Button>
                    <Button  onClick={() => stopRecordingNewModal()}>STOP</Button>
                  </Box>)}
                </Grid>
                </Grid> */}
            </div>      
          </div>
       
      </div>
      )}


        {!recordingInProgress && (
          <div ref={liveText} style={{}}>
            <Modal open={processingRecording} onClose={() => { setProcessingRecording(false); }}>
              <ModalDialog layout="fullscreen" style={{opacity:0.6,backgroundColor:'#000000'}}>
                      <ModalClose size="lg" onClick={() => cancelNewRecording()} />
                      <div style={{textAlign:'center',fontSize:'2em',margin:'5%',color:'#ffffff'}}>
                        <div style={{paddingBottom:'20%'}}>xProcessing...{processingRecording}</div>
                        <div style={{fontSize:'0.5em',textAlign:'left'}}>{transcriptJM}</div>
                        <div style={{margin:'20%'}}>
                        <CircularProgress />
                        </div>
                      </div>
              </ModalDialog>
            </Modal>
            {secondStageInProgress && !spinnerWaiter && (
              <div style={{zIndex:900,backgroundColor:'#ffffff',padding:'20px'}}>
                <div style={{padding:'10px',whiteSpace:'pre-wrap'}}>{transcriptDone}</div>
                <div style={{justifyContent:'space-between',display:'flex' }}>
                <Button onClick={retryProcessing}>Retry</Button>
                <Button onClick={cancelProcessing}>Forget it</Button>
                <Button onClick={() => recordingModalNewOpen()}>Re-record</Button>
                  <Button onClick={acceptProcessing}>Keep it!</Button>
                </div>
              </div>
            )}
            {processingRecording && (
              <div>
                P1rocessing...
              </div>
              )}
        </div>
        )}
      </div>
  )
  
}

const RecordingModalX = ({
  aiPolish,
  setAiPolish,
  cancelRecording,
  setOpen,
  open,
  recordingInProgress,
  startRecordingProcess,
  stopRecordingProcess,
  tryRecordingAgain,
  pauseRecordingProcess,
  startFromModalRecording,
  maxTime,
  renderTime={renderTime},
  stopRecordingProcessSingle,
  startRecordingProcessSingle,
  stopRecordingModal,
  recordingModalOpen,
  keepRecording,
  transcript,
  recordingPaused,
  saveFilters,
  processingRecording,
  recordingExtraContext,
  transcriptDone,
  setTranscriptDone,
  recordingSingle,
  setProcessingRecording,
  transcriptJM,
  totalTranscript,
  runningTotalText

}) => {

    const liveText = useRef();  // Create a ref

    const [counter, setCounter] = useState(0);
    const [barColor, setBarColor] = useState('#94a7f8');

    // const secondsLeft = 96;
    const secondsLeft = usageRemaining * 60;
    const maxTime2 = secondsLeft;
    
  /*useEffect(() => {
    // how to only start on recording and reset on stop recording
    if (recordingInProgress) {
      if (counter >= maxTime2) {
        stopRecordingModal();
      }
      if (counter > 70) {
        setBarColor('#ed5e5b');
      }
      const timer =
      counter < maxTime2 && !recordingPaused && setTimeout(() => {
     
        setCounter(counter + 10)
      }, 2000);
    }
    if (!recordingInProgress) {
      setCounter(0);
      setBarColor('#94a7f8');
    }
}, [counter, recordingInProgress, recordingPaused]);
*/

    useEffect(() => {
   
     // if (!processingRecording && !recordingInProgress) {
      const scrollableDiv = liveText.current;
      scrollableDiv.scrollTop = scrollableDiv.scrollHeight;
     // setTranscriptDone(totalTranscript + transcriptJM.text);
   // }
    }, [transcriptJM]);  

let extraHeader = '';

if (recordingExtraContext && recordingExtraContext.name) {
    extraHeader = recordingExtraContext.name;
    if (recordingExtraContext.type === 'action') {
      if (recordingExtraContext.name === 'intday') {
        extraHeader = 'INT.  - DAY';
      }
      if (recordingExtraContext.name === 'extday') {
        extraHeader = 'EXT. - DAY';
      }
      if (recordingExtraContext.name === 'intnight') {
        extraHeader = 'INT. - NIGHT';
      }
      if (recordingExtraContext.name === 'extnight') {
        extraHeader = 'EXT. - NIGHT';
      }
      if (recordingExtraContext.name === 'actionline') {
        extraHeader = '';
      }
    }

   // if (recordingExtraContext.type === 'character') {
    //  extraHeader = `${recordingExtraContext.name}:`;
   // }
  }
  const opendisplay = open === true ? 'block' : 'none';

  const liveTextHeight = '300px';


  return (
    <div>

        {recordingInProgress && (
            
      <div id="reworkedRecording">
          <div>
            <div style={{width:'100%',backgroundColor:'#f5f5f5',height:'15px'}}>
          
            </div>
            <div ref={liveText}  style={{margin:'10px',backgroundColor:'#ffffff',padding:'10px',height:'200px', overflow:'scroll'}}>
            {extraHeader && (<div style={{Width:'100%',textAlign:'center'}}>
              <b> {/* extraHeader */}</b>
            </div>)}
            {runningTotalText}
         <div style={{margin:'20px',textAlign:'center'}}> Recording in progress...</div>
            <div className="centerrec">
              <div className="circle pulse red"></div>
            </div>

   
            </div>
            <div>
              <Grid container spacing={2} sx={{}}>
                
                <Grid sx={{border:'1px solid #cccccc;'}} xs={4} md={4}>
                  <div>
                    <Button onClick={() => {
                      cancelRecording();
                      return setOpen(false);
                    }}>CLOSE</Button>
                  </div>
                </Grid>
                <Grid xs={8} md={8} sx={{border:'1px solid #cccccc;'}}>
                {recordingPaused && (<div><Button style={{margin:'1em'}} onClick={() => startFromModalRecording()}>RESUME</Button></div>)}
                {!recordingPaused && (
                  <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', justifyContent:"flex-end"  }}>
                    <Button sx={{marginRight:'10px;'}} onClick={() => pauseRecordingProcess()}>2PAUSE</Button>
                    <Button  onClick={() => stopRecordingModal()}>STOP</Button>
                  </Box>)}
                </Grid>
              </Grid> 
            </div>      
          </div>
          </div>
        )}
        {!recordingInProgress && (
          <div ref={liveText}>
            <Modal open={processingRecording} onClose={() => { setProcessingRecording(false); }}>
              <ModalDialog layout="fullscreen" style={{opacity:0.6,backgroundColor:'#000000'}}>
                      <ModalClose size="lg" onClick={() => cancelRecording()} />
                      <div style={{textAlign:'center',fontSize:'2em',margin:'5%',color:'#ffffff'}}>
                        <div style={{paddingBottom:'20%'}}>Processing...{processingRecording}</div>
                        <div style={{fontSize:'0.5em',textAlign:'left'}}>{transcriptJM}</div>
                        <div style={{margin:'20%'}}>
                        <CircularProgress />
                        </div>
                      </div>
              </ModalDialog>
            </Modal>
              {processingRecording && (
                <div>
                  Prcocessing...
                </div>
                )}
        </div>
        )}
      </div>

  )
  
}

function App() {

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [content, setContent] = useState('');
  const [project, setProject] = useState({ name:'', species: ''});
  const [totaldoc, setTotalDoc] = useState('');
  const [secondTotaldoc, setSecondTotalDoc] = useState('');
  const [totalText, setText] = useState('');
  const [value, setValue] = useState('');
  const [cursorPosition, setCursorPosition] = useState(0);
  const [blockProcessing, setBlockProcessing] = useState(false);
  const [isLoadingFeed, setIsLoadingFeed] = useState(true);
  const [isCharactersLoading, setIsCharactersLoading] = useState(true);

  const [editingCharacters, setEditingCharacters] = useState(false);
  const [transcriptJM, setTranscriptJM] = useState('');
  const [totalTranscript, setTotalTranscript] = useState('');

  const [reordering, setReordering] = useState({ordering: false, direction:false});


  const [feedItems, setFeedItems] = React.useState([]);

  const [characters, setCharacters] = useState<any[]>([]);
  const [chapter, setChapter] = useState({ id:'', dat: { name: ''} });

  const [isRecognizing, setIsRecognizing] = useState(false);
  const [shouldListen, setShouldListen] = useState(false);
  const [emergencyStop, setEmergencyStop] = useState(false);

  const [elapsed, setElapsed] = useState(0);

  const userid = useAuth();

  const fetchedArray = [];

  const navigate = useNavigate();

  let location = useLocation();

  const [jumpPoint, setJumpPoint] = useState(null);

  const [usageRemaining, setUsageRemaining] = useState('begin');
  const userInfo = React.useContext(UserContext) as any;


  useEffect(() => {
    if (jumpPoint !== null && document.getElementById(jumpPoint)) {
      document.getElementById(jumpPoint).scrollIntoView({ behavior: 'instant', block: 'nearest', inline: 'start' })
    }
  }, [jumpPoint]);

// check usage
useEffect(() => {
 

  if (userInfo.userInfo.subs) {
    const minutesCount = userInfo.userInfo.subs.minutes_count;
    // const minutesCount = 9000;
    const minutesAllowed = userInfo.userInfo.subs.minutes_allowed;


    if (userInfo.userInfo.subs.usage_expires > (Date.now() / 1000) && minutesCount <  minutesAllowed) {
 
      const usage = (userInfo.userInfo.subs.minutes_allowed - userInfo.userInfo.subs.minutes_count);  
  
      setUsageRemaining(usage);
    }
    if (userInfo.userInfo.subs && userInfo.userInfo.subs.usage_expires > (Date.now() / 1000) && minutesCount >=  minutesAllowed) {
      setUsageRemaining(0);
   
    }
  }
}
,[userInfo]);






  // stuff for whisper
  const {
    recording,
    speaking,
    transcribing,
    transcript,
    pauseRecording,
    startRecording,
    stopRecording,
    noMore,
    whisperError,
    runningTotalText,
  } = useWhisper({
    apiKey: process.env.REACT_APP_OPENAI_AI_KEY,
    streaming: false,
    timeSlice: 1_000, // 1 second
    removeSilence: true,
    // nonStop: true, // keep recording as long as the user is speaking
    stopTimeout: 8000,
    autoStart: false,

    //streaming: true,
    //timeSlice: 1_000, // 1 second
    whisperConfig: {
     prompt: 'Transcribe the text exactly with no changes', // you can pass previous conversation for context
    },
  });

  const postRecording = async (totaltext:any, skippingRender = false) => {
    let feedItems2 = [];
    let formattedText = totaltext.text ? totaltext.text : totaltext;
    let orderingoldtwo = feedItems[feedItems.length - 1]?.dat.ordering || 0;
// so this is the old way.
       // orderingoldtwo = orderingoldtwo + 1;
   const amountUsed = elapsed;

    if (!editingPhase) {
      // save the feed
      feedItems2 = await saveFeedBlock(userid?.user?.uid, location.state.id, formattedText, orderingoldtwo, recordingExtraContext, reordering, location.state.chapterId, amountUsed, userInfo, userInfo.setUserInfo); 
    } else {
      feedItems2 = await editFeedBlock(userid?.user?.uid, location.state.id, formattedText, editingItem.id, false, location.state.chapterId, amountUsed, userInfo, userInfo.setUserInfo)
    }

    setFeedItems(feedItems2.feed as any);

    setSpinnerWaiter(false);

    if (characterOption) {
      setButtonRecording('character',characterOption);
      setCharacterOption(false);
      
      return;
    }
   if (actionOption) {
      setButtonRecording('action',actionOption);
      setActionOption(false);
      return;
    }
  

   
    let charVal = '';
    let actVal = '';

    if (recordingExtraContext && recordingExtraContext.type === 'character') {
      charVal = recordingExtraContext.name;
      //formattedText = `${recordingExtraContext.name}:\n${formattedText}`;
    }
    if (recordingExtraContext && recordingExtraContext.type === 'action') {
      actVal = recordingExtraContext.name;
     // formattedText = `${recordingExtraContext.name}:\n${formattedText}`;
    }
    //setTotalDoc(totaltext);
    setEditingPhase(false);
    setRedoingSection(false);

    setProcessingRecording(false);
    /*
 
    */
    setJumpPoint(feedItems2.docRefo);
   // document.getElementById(feedItems2.docRefo).scrollIntoView({ behavior: 'instant', block: 'nearest', inline: 'start' })
    // need to factor in what we have already...
   /* const firstChunk = totaldoc.substring(0,cursorPosition);
    const secondChunk = totaldoc.substring(cursorPosition);
  
    console.log(firstChunk + totaltext + secondChunk)*/
    //setTotalDoc(firstChunk + totaltext + secondChunk);
    // setTotalDoc('banananaas')

  
  //  setOpen(false);
  }

  const handleChange = (content:any, delta: any) => {

    setTotalDoc(content);
    // setCursorPosition(delta.ops[0].retain);
  }

  const breakAndInsert = (project: any) => {
    // OK so each one of these is a feed unit.
    // with an editable box and a set of actions.
    const content = project.content;
    setContentArray(content);
    const projectDetails = project.project;
    let totaldoc2 = '';
    content.forEach((el:any, i:number) => {

      totaldoc2 = totaldoc2 + el.dat.content;
    });
    setTotalDoc(totaldoc2);
  };


  const exportText = () => {
    const exportGenericFile = (text: string, fileName: string) => {
      const aElement = document.createElement("a");
      aElement.href = "data:text/plain;charset=utf-8," + text;
      aElement.download = fileName;
      aElement.style.display = "none";
      document.body.appendChild(aElement);
      aElement.click();
      document.body.removeChild(aElement);
    }

    // let exportData = totaldoc.replace(/<[^>]+>/g, '');
    let exportData = totaldoc;
    const fileName = project.name.replace(/[^A-Z0-9]/ig, "_") + Math.random().toString(36).substr(2) + '.txt';
    exportData = exportData.replace(/<p>/g,'<br>');
    exportData = exportData.replace(/<\/p>/g,'');
    exportData = exportData.replace(/<br>/g,'\n');
    exportGenericFile(exportData, fileName)
  };


  const exportText2 = () => {
    const exportGenericFile = (text: string, fileName: string) => {
      const aElement = document.createElement("a");
      aElement.href = "data:text/plain;charset=utf-8," + text;
      aElement.download = fileName;
      aElement.style.display = "none";
      document.body.appendChild(aElement);
      aElement.click();
      document.body.removeChild(aElement);
    }

    // let exportData = totaldoc.replace(/<[^>]+>/g, '');
    const feedtotal = feedItems.map((el:any) => {
      return el.dat.content;
    }).join('\n');
    let exportData = feedtotal;
    const fileName = project.name.replace(/[^A-Z0-9]/ig, "_") + Math.random().toString(36).substr(2) + '.txt';
    exportData = exportData.replace(/<p>/g,'<br>');
    exportData = exportData.replace(/<\/p>/g,'');
    exportData = exportData.replace(/<br>/g,'\n');
    exportGenericFile(exportData, fileName)
  };



  const [editingPhase, setEditingPhase] = useState(false);
  const [editingItem, setEditingItem] = useState(false);
  const [editingItemContent, setEditingItemContent] = useState('');


  const processTranscript = async (transcript:any, elapsed: any) => {
    if (!recordingCancelled) {
      // skip processing while debugging to save credits
      const skipping = false;
      if (skipping === true || transcript.text === '.') {

        setTranscriptDone(transcript.text);
        setProcessingRecording(false);
        if (characterOption) {
          setButtonRecording('character',characterOption);
          setCharacterOption(false);
          return;
        }
       if (actionOption) {
          setButtonRecording('action',actionOption);
          setActionOption(false);
          return;
        }
        return;
      }
      transcript.text = transcript.text.replace('.','');


      const aiPolishValue = aiPolish ? 'polish' : 'none';
      // flag to false to skip AI processing
      const aiProcessingScreenplay = true ;
      const aiProcessingProse = true;
      
      if ((project.species === 'prose' && aiProcessingProse) || (project.species === 'screenplay' && aiProcessingScreenplay)) {
     //const resp = await fetch(`https://fictory-server.onrender.com/api/processTranscription`, {  
     
   //////////////
   setSpinnerWaiter(true);
      const resp = await fetch(`${endpoint}api/processTranscription`, {
    //const resp = await fetch(`http://localhost:5001/api/processTranscription`, {

          method: 'POST',
          headers: {
          // Accept: 'application.json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({transcript, aiSelection: aiPolishValue, extra: recordingExtraContext, species: project.species})
        }).then(async(response) => {
          const msg = await response.json();




          // process content here
          if (!response.ok) {
            setSpinnerWaiter(false);
            throw new Error(
              `This is an HTTP error: The status is ${response.status}`
            );
          } else {
            setSpinnerWaiter(false);
            // NEED TO SWITCH THIS DEPENDING IF WE WANT POST PROCESSING OR NOT
        // postRecording(msg.totaltext);

        if (project.species === 'prose') {
          let returnedMessage = msg.totaltext.replace(/^"|"$/g, '');
          setTranscriptDone(returnedMessage);
          setProcessingRecording(false);
          
        }
        if (project.species === 'screenplay') {
          setTranscriptDone(msg.totaltext);
          setProcessingRecording(false);
          if (characterOption) {
            setButtonRecording('character',characterOption);
            setCharacterOption(false);
          }
         if (actionOption) {
            setButtonRecording('action',actionOption);
            setActionOption(false);
          }
          // we don't show second screen for screenplay, just cut straight to process.
          postRecording(msg.totaltext);

          
        }
      //    postRecording(msg.totaltext);
          }
        });

        ////////////////////
      } else {
        setTranscriptDone(transcript.text);

        postRecording(transcript.text);
       //postRecording(transcript.text);
      }
 
    // postRecording(transcript);
    } else {
   
    }
  }

  useEffect(() => {
    if (whisperError) {
      setProcessingRecording(false);
      cancelRecording();
    }
   // }
  }, [whisperError]);

  useEffect(() => {
    if (userid.user && userid.user.uid) {
      if (!location.state) {
        const pathnameParts = location.pathname.split('/');
// item1value will be at index 2 in the pathnameParts array

const chapterId = pathnameParts[3];
const id = pathnameParts[2]
        location.state = {
          id: id,
          chapterId: chapterId
        }
      } else {
        location.state = location.state;
      }
        getProject(location.state.id, userid?.user?.uid).then((project) => {
          let index = project.project.chapters.findIndex((obj: { [x: string]: { [x: string]: any; }; }) => obj['id'] === location.state.chapterId);
          if (index === -1) {
          } else {
            setChapter(project.project.chapters[index]);
          }
          // find the array where the id matches location.state.chapterId
          // 
             setProject(project?.project as any)
         });
            // call this inside feed window
            // breakAndInsert(project);
            
            getFeedBlocks(userid.user.uid, location.state.id, location.state.chapterId, false).then((feedItems) => {
            setFeedItems(feedItems as any)});
            keepRecording();
          //  setJumpPoint(feedItems.docRefo);
           

            // OK so now we can set 
            getCharacters(userid?.user?.uid, location.state.id).then((characters) => {
            setCharacters(characters as any);
            setIsCharactersLoading(false);
           });
        }
  }, [userid?.user?.uid]);

  /*
  useEffect(() => {
    setTotalTranscript(`${totalTranscript}${transcript.text}`);
    //if (recordingInProgress) {
    setTranscriptJM(transcript.text);
   // }
  }, [transcript]);
  */



 useEffect(() => { 
    setTimeout(() => {
      setIsLoadingFeed(false);
    },500);
  }, [feedItems]);

  useEffect(() => {
  /*  await fetchEditingItem(editingItem).then((item) => {
     */
      feedItems.forEach((item:any) => {
      if (item.id === editingItem.id) {
      
        setTotalDoc(item.dat.content as any);
      }
  //   setEditingItemContent(item as any);
    })
  }, [editingItem])
  const buttonBlockRef = useRef(null);
  useEffect(() => {
    const xButtonBlockHeight = (window.innerHeight - buttonBlockRef.current.clientHeight);
    setButtonBlockHeight(buttonBlockRef.current.clientHeight);
  }, [buttonBlockRef])

  const [recordingSingle, setRecordingSingle] = useState(false);
  const [recordingInProgress, setRecordingInProgress] = useState(false);
  const [recordingExtraContext, setRecordingExtraContext] = useState({name: '', type: '', id: ''});
  const [recordingPaused, setRecordingPaused] = useState(false);
  const [recordingCancelled, setRecordingCancelled] = useState(false);
  const [processingRecording, setProcessingRecording] = useState(false);
  const [redoingSection, setRedoingSection] = useState(false)

  const [editingTitle, setEditingTitle] = useState(false);
  const [transcriptDone, setTranscriptDone] = useState('');
  const [open, setOpen] = useState(false);

  const [contentArray, setContentArray] = useState([]);

  const [aiPolish, setAiPolish] = useState(false);
  const [buttonBlockHeight, setButtonBlockHeight] = useState(0);

  const [characterOption, setCharacterOption] = useState(false);
  const [actionOption, setActionOption] = useState(false);

  const [secondStageInProgress, setSecondStageInProgress] = useState(false);

  const [stoppedRecordingFromButton, setStoppedRecordingFromButton] = useState(false);
  const [spinnerWaiter, setSpinnerWaiter] = useState(false);

  const userContext = React.useContext(UserContext) as any;
  // const usageRemaining = checkUsageRemaining(userContext.userInfo);
  
  useEffect(() => { 
    if (secondStageInProgress && !recordingInProgress && transcriptJM && transcribing === false) {
        setBlockProcessing(true);
        processTranscript({text: transcriptJM}, elapsed);
      }
    }, [secondStageInProgress]);

  useEffect(() => {
   
  }, [shouldListen]);

  const fetchEditingItem = async (id: string) => {

    return feedItems.forEach((item:any) => {
      if (item.id === id) {
        return item;
      }
    })
  }

function startSpeechRecording() {

  
  if (isRecognizing) {
   
     // recognition.stop();
      setIsRecognizing(false);
    //  document.getElementById("transcript").textContent = "Transcript will appear here...";
  } else {
      
     if (shouldListen) {
    //    recognition.start();
      }
  }
}

  const startRecordingProcess = () => {
    setElapsed(0);
    setTranscriptJM('');
    setRecordingCancelled(false);
    setRecordingInProgress(true); 
    setRecordingPaused(false); 
    startRecording();   
  };
  
  const stopRecordingProcess = () => {
    setTranscriptJM('');
    setRecordingInProgress(false); 
    setTotalTranscript('');  
    stopRecording();
  };
  
  const startRecordingProcessSingle = () => {
    setElapsed(0);
    setTranscriptJM('');
    setRecordingSingle(true);
    startRecordingProcess();
  }
  
  const stopRecordingProcessSingle = () => {
    setTranscriptJM('');
    setRecordingSingle(false);
    stopRecordingProcess();
  }
  
  const tryRecordingAgain = () => {
    setTranscriptJM('');
    setEditingPhase(true);
  
    startRecording();
    setRecordingCancelled(false);
    setRecordingInProgress(true);
    setRecordingPaused(false);
    // stop recording
    // start recording
  }
  
  const cancelRecording = () => {
    setTranscriptJM('');
  
    // stop recording
    setRecordingInProgress(false);
    setTotalTranscript('');
    stopRecording();
    setRecordingCancelled(true);
    setSecondStageInProgress(false);
  }
  
  const keepRecording = () => {
    setTimeout(() => {
      endFeedRef.current?.scrollIntoView({ behavior: 'instant', block: 'nearest', inline: 'start' })
    }, 500);

    setOpen(false);

    // STORE THE TRANSCRIPT....
    // stop recording
  }

  const startNewRecording = () => {
   
    // show modal

    // start dictating
    // update dictation transcript
  }

  const stopNewRecording = () => {

    if (project.species === 'prose') {

      processTranscript({text: transcriptJM}, elapsed); 
      setSecondStageInProgress(true);
    } else {
      processTranscript({text: transcriptJM}, elapsed);
    }
    // stop dictating
    // show preview window
  }

  const stopDictation = () => {
 
  }

  const cancelNewRecording = () => {
   

      setTranscriptJM('');
      // stop recording
      setRecordingInProgress(false);
      setTotalTranscript('');
      stopDictation();
     // stopNewRecording();
      setRecordingCancelled(true);
    // stop dictating
    // reset and ignore dictation transcript
  }

  const acceptProcessing = () => {

    postRecording(transcriptDone);
    setSecondStageInProgress(false);
  }

  const retryProcessing = () => {
    // do the processing again
 
    setBlockProcessing(true);
      processTranscript({text: transcriptJM}, elapsed);
  }


  const cancelProcessing = () => {
    // do the processing again
  cancelRecording();
  
  }


  const newProcessing = (text) => {
    // do the processing AI API call
   
    const processedText = text + ' - processed';
    
    // update the contents of the preview window
  }

  const confirmNewRecording = () => {
    // Save to Firebase and update blocks
  }

  const startFromModalNewRecording = (value: any, type:any) => {
    setIsRecognizing(true);
    // startSpeechRecording();
    setReordering({ordering: false, direction:false});
    setRecordingInProgress(true);
    setRecordingPaused(false);
    setBlockProcessing(false);
    setTranscriptDone('');
    startNewRecording();
    setRecordingCancelled(false);
    setRecordingExtraContext(value);
    setJumpPoint('recordingX');
  
  }

  const stopRecordingNewModal = () => {
    setIsRecognizing(false);
    setShouldListen(false);
    stopNewRecording();
  //  setProcessingRecording(true);
    setTotalTranscript('');
  
   // setprocessingRecording(false);
  }
  
  
  const pauseRecordingNewProcess = () => {
    setRecordingPaused(true); 
   
    // pauseRecording();
  }
  
  const startFromModalRecording = (value: any, type:any) => {
    setReordering({ordering: false, direction:false});
    setRecordingInProgress(true);
    setRecordingPaused(false);
    setBlockProcessing(false);
    setTranscriptDone('');
    
    startRecording();
    setRecordingCancelled(false);
   setRecordingExtraContext(value);

    setJumpPoint('recordingX');
  }
  
  
  const stopRecordingModal = () => {
   /*
    stopRecording();
    setProcessingRecording(true);
    setRecordingInProgress(false);
    setTotalTranscript('');
    */
    // stopRecordingNewModal();
  
   // setprocessingRecording(false);

  }
  
  const saveFilters = () => {
    // 
    
    setOpen(false);
  }
  const pauseRecordingProcess = () => {

    setRecordingPaused(true); 
    pauseRecording();
  };
  
  const recordingModalOpen = (value:any, type:any) => {
    setTranscriptJM('');
    setOpen(!open);
    ///x x x x xskj 
    startFromModalRecording(value, type);
    // close
    // stop recording
    // include countdown dial
  
    // set recordingInProgress to true
  }

  const recordingModalNewOpen = (value:any, type:any) => {
    setSecondStageInProgress(false);
    setEmergencyStop(false);
    setElapsed(0);
    setTranscriptJM('');
    setShouldListen(true);
    setOpen(!open);
    ///x x x x xskj 
    startFromModalNewRecording(value, type);
    // close
    // stop recording
    // include countdown dial
  
    // set recordingInProgress to true
  }

const renderTime = ({ remainingTime }) => {
  if (remainingTime === 0) {
    return <div className="timer">Too late...</div>;
  }

  return (
    <div className="timer">
            <div className="value">{remainingTime}</div>
            <div className="text">Seconds</div>
      <div className="text">remaining</div>


    </div>
  );
};

  
  const setReviewMode = () => {
    // set recordingInProgress to true
  }
  
const maxTime = 10;

const cursorSet = (value:number) => {
  setCursorPosition(value);
}

const editSection = (item: any) => {
  //props.editSection(item);
  setEditingPhase(true);
  setEditingItem(item);
}

const deleteSection = (item:any) => {
  //props.deleteSecton(item);
  if (confirm("Are you sure you want to delete this section?")) {
    deleteFeedBlock(userid?.user?.uid, location.state.id, item.id, location.state.chapterId).then((feeditems) => {
      setFeedItems(feeditems);
    });
  }
}

const redoSection = (item:any) => {
  //props.redoSection(item);

  setEditingPhase(true);
  setRedoingSection(true);
  setEditingItem(item);


  recordingModalNewOpen({value: item.dat.name, type: item.dat.type}, item.dat.type);
  setRecordingExtraContext(item.dat.recordingExtraContext);
//  console.log('redoSection',triggerValue);
}

const insertSection = (direction:string, ordering: number, item: any) => {
 
  recordingModalNewOpen();
  const orderingo = item.dat.ordering;

  setReordering({direction, ordering: orderingo});
  setRecordingExtraContext(item.dat.recordingExtraContext);
}

const setButtonRecording = (type:string, value:any) => {
  setReordering({ordering: false, direction:false});
  if (type === 'character') {
    recordingModalNewOpen(value, type);
  }
  if (type === 'action') {
    recordingModalNewOpen(value, type);
  }
}

const speechRef = useRef();


const characterAction = (value:any) => {
  const characterInsert = '\n\n' + value +'\n';
  if (recordingInProgress === true) {
    setCharacterOption(value);
    setSpinnerWaiter(true);
    if (speechRef.current && speechRef.current.stopRecording) {
      speechRef.current.stopRecording(true);
    }
   // stopRecordingNewModal();
   
  } else {
    setButtonRecording('character',value);
  }
}

const selectAction = (value:any) => {
  if (recordingInProgress === true) {
    setActionOption(value);
    setSpinnerWaiter(true);
    if (speechRef.current && speechRef.current.stopRecording) {
      speechRef.current.stopRecording(true);
    }
   // stopRecordingNewModal();
  } else {
    setButtonRecording('action',value);
  }
}


const breakAndSave2 = async (editingItem) => {
  const id = editingItem.id;
 
  const feeditems = await editFeedBlock(userid?.user?.uid, location.state.id, totaldoc, id, false, chapter.id, amountUsed, userInfo, userInfo.setUserInfo);
  fetchData
  setFeedItems(feeditems.feed);

  setJumpPoint(feeditems.docRefo);
  setEditingPhase(false);
  setRedoingSection(false);

}

const breakAndSave = async () => {
  const docLength = 50;
  const amountOfDocs = Math.ceil(totaldoc.length / docLength);
  let starter = 0;
  let ender = docLength;

  for (let i=1; i <= amountOfDocs;i++) {
    const docContent = totaldoc.substring(starter,ender);
    starter = starter + docLength;
    ender = ender + docLength;
    if ( i <= contentArray.length) {
      const url1 = `users/${userid}/projects/${location.state.id}`;
      const url2 = `/content/${contentArray[i-1]['id']}`;
      const docRefo = doc(db, `users/${userid?.user?.uid}/projects/${location.state.id}/content/`, contentArray[i-1]['id']);
      const dats2 = {
        content: docContent,
        ordering: i
      }
      updateDoc(docRefo, dats2).then(docRef => {
      })
      .catch(error => {
      })
    } else {
      const docRef = addDoc(collection(db, `users/${userid?.user?.uid}/projects/${location.state.id}/content/`), {
        content: docContent,
        ordering: i
      })
      .catch(error => {
    })
  
    }
    getProject(location.state.id, userid?.user?.uid).then((project) => {
      breakAndInsert(project);
      setProject(project?.project as any)
    });
  }
  if (amountOfDocs < contentArray.length) {
    const jello = contentArray.length - amountOfDocs;
    const reversal = contentArray.reverse();
    for (let j=0; j < jello; j++) {
      const delRef = doc(db, `users/${userid?.user?.uid}/projects/${location.state.id}/content/`, contentArray[j]['id']);
     deleteDoc(delRef);
    }
  }
}

if (!project) {
  return (
  <Navigate to='/list'/>
  )
}

const endFeedRef = useRef(null);
const saveTitleEdit = async(e:any) => {
  e.preventDefault();
  editTitle();
  const title = e.target.currentProjectTitle.value;
  editProjectTitle(title, userid?.user?.uid, location.state.id);
}



const backFromEditor = () => {
  setEditingPhase(false);
  setRedoingSection(false);
}

const goBackToList = () => {
  navigate(`/list/`, {

  });
}

const editTitle = () => {
  editingTitle ? setEditingTitle(false) : setEditingTitle(true);
}



return (
  <div className="App" style={{display:'flex',flexDirection:'column',height:'100%'}}>
    
    <div id="containerBars" ref={buttonBlockRef}>
   <div style={{position:'absolute',top:'5px',zIndex:10,width:'100%',textAlign:'center'}}>{recordingInProgress && project.species === 'screenplay' && (<RecordingButton open={() => recordingModalNewOpen()} recordingInProgress={recordingInProgress} setStoppedRecordingFromButton={setStoppedRecordingFromButton} usageRemaining={usageRemaining}/>)}</div>


    <div className="navbarB" style={{backgroundColor:'#fcfd91',color:'#323d43',height:'50px',paddingLeft:'12px'}}>
{!editingPhase && (<div onClick={goBackToList}><ArrowBackIosIcon /></div>)}


    {!editingTitle && !secondStageInProgress && (<div style={{width:'100%',textAlign:'center'}}><b>{chapter.dat.name}</b></div>)}
    {secondStageInProgress && (<div style={{width:'100%',textAlign:'center'}}><b>How's this?</b></div>)}
</div>


      {!editingPhase &&  project.species === 'screenplay' && secondStageInProgress === false && usageRemaining > 0 &&
      (<ActionBar
              selectAction={selectAction}
              characters={characters}
              characterAction={characterAction}
              location={location}
              setContent={setContent}
              setCharacters={setCharacters}
              editingCharacters={editingCharacters}
              setEditingCharacters={setEditingCharacters}
            />
      )}
{!editingPhase && secondStageInProgress === false && usageRemaining <= 0 && usageRemaining !== 'begin' &&
  <div style={{backgroundColor:'#ffffff',padding:'20px'}}>Sorry, you have no usage remaining. Please <a href='/upgrade'>upgrade your account.</a></div>
}
{usageRemaining === 'begin' && (
  <div style={{backgroundColor:'#ffffff',padding:'20px'}}>LOADING...</div>
)}

{project.species === 'prose' && !secondStageInProgress && usageRemaining > 0 && (
          <Row>
            <ButtonBar
              startRecordingProcess={startRecordingProcess}
              stopRecordingProcess={stopRecordingProcess}
              pauseRecordingProcess={pauseRecordingProcess}
              startRecordingProcessSingle={startRecordingProcessSingle}
              stopRecordingProcessSingle={stopRecordingProcessSingle}
              recordingModalNewOpen={recordingModalNewOpen}
              recordingInProgress={recordingInProgress}
              recordingSingle={recordingSingle}
              aiPolish={aiPolish}
              setAiPolish={setAiPolish}
              editingPhase={editingPhase}
              feedItems={feedItems}
              isLoadingFeed={isLoadingFeed}
              editingCharacters={editingCharacters}
              setStoppedRecordingFromButton={setStoppedRecordingFromButton}
              usageRemaining={usageRemaining}
            />
          </Row>
          )}
     
      </div>

      {!recordingInProgress && !secondStageInProgress && !spinnerWaiter && (
        <FeedArea user={userid} redoSection={redoSection} insertSection={insertSection} feedItems={feedItems} editSection={editSection} deleteSection={deleteSection} location={location} project={project} setFeedItems={setFeedItems} />
)}

      {(feedItems.length === 0 && !isLoadingFeed && !recordingInProgress && !editingCharacters && characters.length !== 0) && (
    <div style={{margin:'10px',fontWeight:'bold',fontSize:'1.2em'}}>Select a character or action to start dictating!</div>
  )}

  
      
<div style={{padding:'10px',flex:1,display:'flex',flexDirection:'column',height:'100%'}}>
    {editingPhase && !redoingSection &&
      (
        <div>
          <Button style={{margin:10}} onClick={function() { backFromEditor(); }}><ArrowBackIosNew /></Button>
    <Editor editingItem={editingItem} value={totaldoc} species={project.species} insertion={content} handleChange={handleChange} extras={totaldoc} cursorSet={cursorSet} />
   
          <Button onClick={function() { breakAndSave2(editingItem); }} style={{marginRight:'45px',marginTop:'15px',margin:10}}>Save</Button>
          {/*<Button onClick={function() { exportText(); }}>EXPORT</Button>*/}
        </div>
      )}

 
    {(!editingPhase || (editingPhase && redoingSection)) &&
      (
        <div style={{flex:1,display:'flex',flexDirection:'column',height:'100%'}}>
   
 
 <div style={{padding:'5px',flex:1,display:'flex',flexDirection:'column',height:'100%'}}>

          {/*<ScreenRecordingTwo postRecording={postRecording} ref={childRef} />*/}
          {/*<ForwardedRecordingButtonsNew postRecording={postRecording} ref={recordingButtonsRef}  />*/}

       
        <div id="recordingX" style={{flex:1,display:'flex',flexDirection:'column',height:'100%'}}>
          <RecordingModalNew
            aiPolish={aiPolish}
            setAiPolish={setAiPolish}
            cancelNewRecording={cancelNewRecording}
            setOpen={setOpen}
            recordingInProgress={recordingInProgress}
            startRecordingProcess={startRecordingProcess}
            stopRecordingProcess={stopRecordingProcess}
            tryRecordingAgain={tryRecordingAgain}
            keepRecording={keepRecording}
            pauseRecordingNewProcess={pauseRecordingProcess}
            startFromModalNewRecording={startFromModalNewRecording}
            maxTime={maxTime}
            renderTime={renderTime}
            cancelProcessing={cancelProcessing}
            startRecordingProcessSingle={startRecordingProcessSingle}
            stopRecordingProcessSingle={stopRecordingProcessSingle}
            saveFilters={saveFilters}
            transcript={transcript}
            recordingSingle={recordingSingle}
            recordingModalOpen={recordingModalOpen}
            stopRecordingNewModal={stopRecordingNewModal}
            transcriptDone={transcriptDone}
            open={open}
            recordingPaused={recordingPaused}
            setRecordingPaused={setRecordingPaused}
            processingRecording={processingRecording}
            recordingExtraContext={recordingExtraContext}
            setTranscriptDone={setTranscriptDone}
            setProcessingRecording={setProcessingRecording}
            transcriptJM={transcriptJM}
            runningTotalText={runningTotalText}
            secondStageInProgress={secondStageInProgress}
            setTranscriptJM={setTranscriptJM}
            retryProcessing={retryProcessing}
            acceptProcessing={acceptProcessing} 
            stoppedRecordingFromButton={stoppedRecordingFromButton}
            setStoppedRecordingFromButton={setStoppedRecordingFromButton}
            setRecordingInProgress={setRecordingInProgress}
            speechRef={speechRef}
            setShouldListen={setShouldListen}
            elapsed={elapsed}
            setElapsed={setElapsed}
            setSecondStageInProgress={setSecondStageInProgress}
            recordingModalNewOpen={recordingModalNewOpen}
            usageRemaining={usageRemaining}
            emergencyStop={emergencyStop}
            setEmergencyStop={setEmergencyStop}
            project={project}
            setSpinnerWaiter={setSpinnerWaiter}
            spinnerWaiter={spinnerWaiter}
            characterOption={characterOption}
            actionOption={actionOption}
      />
        </div>
        {/* 
// @ts-ignore */}
        {/*<ScreenRecordingThree postRecording={postRecording} ref={childRef}  />*/}
    {/*<ForwardedRecordingButtons postRecording={postRecording} ref={recordingButtonsRef} />*/}

          
        </div>



    {/* <Button onClick={function() { breakAndSave(); }} style={{marginRight:'45px',marginTop:'15px'}}>FEED SAVE</Button>
      <Button onClick={function() { exportText(); }}>EXPORT</Button> */}
      </div>
      )}

<div ref={endFeedRef}></div>
     
        {/*<div><Button onClick={function() { exportText2(); }}>EXPORT</Button></div>*/}
    </div>
    </div>

)}


export default App;
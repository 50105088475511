import React from 'react';
import { useState, useEffect } from "react";
import Grid from '@mui/joy/Grid';
import { styled } from '@mui/material/styles';
import { red, green, blue } from '@mui/material/colors';
import Checkout from '../Checkout/Checkout';
import Copyright from '../Copyright';
import IntroHeader from '../IntroHeader/IntroHeader';
import Button from '@mui/joy/Button';
import '../../App.css';
import useAuth from "../../hooks/useAuth";
import { getProducts, subscribe, goToPortal } from '../../app/api/server_fb';
import { CardElement, useStripe } from '@stripe/react-stripe-js';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import UserContext from "../../UserContext";
import { PortalButton } from '../PortalButton';
import { endpoint } from "../../config/firebaseconfig";

/*

.pricingBlock {
}

.pricingList {
}

.pricingHeader {
}


.pricingBox {
}

.pricingPrice {
}

.pricingSubprice {
}

.pricingPayLink {
}

.pricingPlanName {
}

.pricingDesc {
}

*/
const PricingRoot = styled('div')(({ theme }) => ({
  padding: theme.spacing(0),
  [theme.breakpoints.down('md')]: {
    '.pricingBlock': {
      margin:'1em',
    },
    '.pricingPrice': {
      fontSize:'3em',
    },
    '.pricingDesc': {
      fontSize:'1.5em',
      position:'relative',
      bottom:'0.5em',
    },
    '.pricingPlanName': {
      fontSize:'3em',
      padding:0,
    },
    '.pricingHeader': {
      fontSize:'0.8em',
      padding:'0.2em',
      margin:0,
    },

  },
  [theme.breakpoints.up('md')]: {
  },
  [theme.breakpoints.up('lg')]: {
  },
}));

const IntroRoot = styled('div')(({ theme }) => ({
  padding: theme.spacing(0),
  [theme.breakpoints.down('md')]: {
    '.step': {
      fontSize:'1.4em',
      fontWeight:'bold',
    },
    '.stepContainer': {
      fontSize:'1em',
      padding:'1.5em',
    },

  },
  [theme.breakpoints.up('md')]: {
    '.step': {
      fontSize:'1.4em',
      fontWeight:'bold',
    },
    '.stepContainer': {
      fontSize:'1.1em',
      padding: '2em',
    },
    '#subtitle': {

    },
    '#intro': {
      paddingBottom:'1.5em',
      paddingTop:'1.5em',
    },
  },
  [theme.breakpoints.up('lg')]: {
    '.step': {
      fontSize:'1em',
      fontWeight:'bold',
    },
    '.stepContainer': {
      fontSize:'1.3em',
      padding: '3em',
    },
  },
}));

const ProductDisplay = () => (
  
  <section>
    <div className="product">
   LOGO!
      <div className="description">
        <h3>Starter plan</h3>
        <h5>$20.00 / month</h5>
      </div>
    </div>
    <form action={`${endpoint}create-checkout-session`} method="POST">
      {/* Add a hidden field with the lookup_key of your Price */}
      <input type="hidden" name="lookup_key" value="{{PRICE_LOOKUP_KEY}}" />
      <button id="checkout-and-portal-button" type="submit">
        Checkout
      </button>
    </form>
  </section>
);

const Message = ({ message }) => (
  <section>
    <p>{message}</p>
  </section>
);

function Introduction() {


let recognition;
let isRecognizing = false;

// Check for browser support
if ('webkitSpeechRecognition' in window) {
    recognition = new webkitSpeechRecognition();
} else {
    alert("Your browser does not support the Web Speech API. Please use a compatible browser.");
 //   return;
}


// Continuous recognition
recognition.continuous = true;
recognition.interimResults = true;

// When recognition starts
recognition.onstart = function() {
    //isRecognizing = true;
};

// Handle the results
recognition.onresult = function(event) {
    console.log("Result event:", event.results[0][0].transcript);
   // for (let i = event.resultIndex; i < event.results.length; ++i) {
       // if (event.results[i].isFinal) {
            document.getElementById("transcript").textContent = event.results[0][0].transcript;
      //  }
   // }
};

// Handle any errors
recognition.onerror = function(event) {
    console.error("Error occurred in recognition:", event.error);
};

// Handle end of recognition
recognition.onend = function() {
    console.log("Recognition ended")
    isRecognizing = false;
};

// Handle end of speech
recognition.onspeechend = function() {
    console.log("Speech ended")
    recognition.stop();
    isRecognizing = false;
};


// Handle start of speech
recognition.onspeechstart = function() {
    console.log("Speech started")

    isRecognizing = true;
};

function startSpeechRecording() {

    if (isRecognizing) {
        recognition.stop();
        isRecognizing = false;
        document.getElementById("transcript").textContent = "Transcript will appear here...";
    } else {
        recognition.start();
    }
}
///////////////

  const userx = useAuth();

  let [message, setMessage] = useState('');
  let [success, setSuccess] = useState(false);
  let [sessionId, setSessionId] = useState('');

  const [products, setProducts] = useState([])

  useEffect(() => {
    getProducts().then((products) => {
      setProducts(products)
    })
  }, []);

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get('success')) {
      setSuccess(true);
      setSessionId(query.get('session_id'));
    }

    if (query.get('canceled')) {
      setSuccess(false);
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
  }, [sessionId]);

   /*
    if (!success && message === '') {
    return <ProductDisplay />;
  } else if (success && sessionId !== '') {
    return <SuccessDisplay sessionId={sessionId} />;
  } else {
    return <Message message={message} />;
  }
  */



if (userx.user) {

}

const userContext = React.useContext(UserContext)

  return (
    <div style={{backgroundColor:'#f9f7f5'}}>
      <IntroRoot>
      <IntroHeader />
      <div id="header">     
      <div id="intro" className="stepContainer">
        <Grid container spacing={3} sx={{ }}>
          <Grid xs={12} md={12} sx={{  }}>
            <div id="introbox"><div id="subtitle">It's time to talk writing</div>
            <div>2.1zz4B-4abStQTVip</div>
              <div id="intro">
                {/*<div>Fictoo is your creative writing assistant.</div>*/}
                <div className="subTextHeader">Screenwriters and novelists, say goodbye to long hours at the keyboard!</div>
              </div>
              <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                {!userx.user && (
                <div className="subnav-cta" id="login" style={{width:'fit-content',fontSize:'1.5em',fontWeight:'bold',padding:'15px',color:'#ffffff',textAlign:'center'}}><a href="/login" style={{color:'#ffffff'}}>
                  Get started for free</a>
                </div>
                )}
              </div>
            </div>
            {/*<Grid item display={{ xs: "none", md: "block" }}><Button>TRY IT NOW</Button></Grid> */}
          </Grid>
          {/*<Grid container xs={12} md={6}>
            <Grid xs={6} md={12}><img src="/static/images/fictory-icons-2.png" alt="Fictoo" width="100%" height="100%" /></Grid>*/}
          {/* <Grid item xs={6} md={12} display={{ xs: "block", md: "none" }}><Button>TRY IT NOW</Button></Grid>*/}
          {/*</Grid>*/}
        </Grid>
        </div>

{userx.user && userx.user.uid && (
               <form action={`${endpoint}create-portal-session`} method="POST">
        <input
          type="hidden"
          id="session-id"
          name="session_id"
          value={sessionId}
        />
        <input type="hidden" name="customerId" value={userx.user.uid} />
        <input type="hidden" name="customerId" value={userx.user.uid} />
        
    
      </form>
      )}
<div>


  <div>
</div>
<div>DEEPGRAM TEST</div>
<div>

  </div>
<h1>PLANS</h1>
{Object.entries(products).map(([productId, productData]) => {
return (<div className="plans" key={productId}>
<div>
{productData.name} - {productData.description}
</div>
<button onClick={(product) => { subscribe(productData, userx.user)}}>THIS-subscribe</button>
</div>)
})}
</div>
<div>
PORTAL BUTTON:
      {(userContext?.userInfo?.subscriptions && userContext.userInfo?.subscriptions?.length > 0) && (<div>this<PortalButton /></div>)}
   
<button onClick={(product) => { goToPortal(userx.user)}}>portalA</button>
</div>

        <div id="step1" className="stepContainer">
        <Grid container spacing={3} sx={{ }}>
          <Grid xs={12} md={6} sx={{ }}>
          <div className="step">Step 1</div>
            <div className="subStep-l">Choose a story type - screenplay or prose and give it a title</div>
          </Grid>
          <Grid xs={12} spacing={3} md={6} sx={{ }}>
            <div className="stepImg"><img src="/static/images/dog.jpg" /></div>
          </Grid>
        </Grid>
        </div>
        <div id="step2" className="stepContainer">
        <Grid container spacing={3} sx={{ flexGrow: 1}}>
        <Grid xs={12} md={6} sx={{ }}>

            <div className="stepImg"><img src="/static/images/dog.jpg" /></div>
          </Grid>
          <Grid xs={12} md={6} sx={{ }}>
          <div className="step">Step 2</div>
            <div className="subStep-r">Add characters</div>
          </Grid>
        </Grid>
          
        </div>
        <div id="step3" className="stepContainer">
        <Grid container spacing={3} sx={{ flexGrow: 1}}>
          <Grid xs={12} md={6} sx={{ }}>
            <div className="step">Step 3</div>
            <div className="subStep-l">- For screenplays, simply act out your script, using simple buttons for transitions, character and scene changes and descriptions.
          - For prose, read your story and Fictoo will convert it into correclty formatted prose.</div>
          </Grid>
          <Grid xs={12} md={6} sx={{ border:'0px solid #000000;' }}>
            <div className="stepImg"><img src="/static/images/dog.jpg" /></div>
          </Grid>
        </Grid>
          
        </div>
        <div id="step4" className="stepContainer">
        <Grid container spacing={3} sx={{ flexGrow: 1}}>
        <Grid xs={12} md={6} sx={{ border:'0px solid #000000;' }}>
    
            <div className="stepImg"><img src="/static/images/dog.jpg" /></div>
          </Grid>
          <Grid xs={12} md={6} sx={{ border:'0px solid #000000;' }}>
          <div className="step">Step 4</div>
            <div className="subStep-r">Let Fictoo suggest improvements, choose from several preset tones and styles.</div>
          </Grid>
        </Grid>
          
        </div>
        <div id="step5" className="stepContainer">
        <Grid container spacing={3} sx={{ flexGrow: 1}}>
    
          <Grid xs={12} md={6} sx={{ border:'0px solid #000000;' }}>
          <div className="step">Step 5</div>
            <div className="subStep-l">Export your story to a text file ready to import into your favourite writing software for the final edit.</div>
          </Grid>
          <Grid xs={12} md={6} sx={{ border:'0px solid #000000;' }}>
            <div className="stepImg"><img src="/static/images/dog.jpg" /></div>
          </Grid>

        </Grid>
        </div>
      </div>
      </IntroRoot>
      <div id="pricing"></div>Message goes here:
 
    {(!userContext || 1 === 1 ||  (!userContext?.userInfo?.subscriptions || userContext.userInfo.subscriptions.length === 0)) && (
      
      <PricingRoot>

      <div>BUITTON BITSL::::</div>
      <div>
<h1>Welcome home</h1>


</div>

     
      <div className="pricingBox">
        <div id="pricingHeader">Start using Fictoo today!</div>
        <Grid container spacing={0} sx={{ flexGrow: 1}}>
        <Grid xs={12} md={4} sx={{  }}>
            <div className="pricingBlock" style={{border:'1px solid #607cee'}}>
            <span id="pricing"></span>
              <div>
                <div className="pricingHeader">
                  <div className="flashBadge">Best deal!</div>
                  <div className="pricingPlanName">Starter</div>
                  <div className="pricingDesc">Try it out! No credit card required</div>
                </div>
                <div className="pricingContent">
                  <div className="pricingPrice">$0</div>

                  <div className="pricingSubprice">per month</div>
                  <div className="pricingPayLink">
                    <div className="pricingButton">Try for free</div>
                  </div>
                  <div className="pricingList">
                    <ul>
                    <li>500 minutes of audio per month</li>
                    <li>Up to 5 projects</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
          <Grid xs={12} md={4} sx={{  }}>
            <div className="pricingBlock" style={{border:'1px solid #607cee'}}>
            <span id="pricing"></span>
              <div>
                <div className="pricingHeader">
                  <div className="flashBadge">Best deal!</div>
                  <div className="pricingPlanName">Starter</div>
                  <div className="pricingDesc">Try it out! No credit card required</div>
                </div>
                <div className="pricingContent">
                  <div className="pricingPrice">$0</div>
                  <div className="pricingSubprice">per month</div>
                  <div className="pricingPayLink">
                  <form action={`${endpoint}create-checkout-session`} method="POST">
      {/* Add a hidden field with the lookup_key of your Price */}
      <input type="hidden" name="lookup_key" value="{{PRICE_LOOKUP_KEY}}" />
      <button  className="pricingButton" id="checkout-and-portal-button" type="submit">
       Try for free
      </button>
    </form>
                  </div>
                  <div className="pricingList">
                    <ul>
                    <li>500 minutes of audio per month</li>
                    <li>Up to 5 projects</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
          <Grid xs={12} md={4} sx={{  }}>
            <div className="pricingBlock" style={{border:'1px solid #607cee'}}>
            <span id="pricing"></span>
              <div>
                <div className="pricingHeader">
                  <div className="flashBadge">Best deal!</div>
                  <div className="pricingPlanName">Starter</div>
                  <div className="pricingDesc">Try it out! No credit card required</div>
                </div>
                <div className="pricingContent">
                  <div className="pricingPrice">$0</div>
                  <div className="pricingSubprice">per month</div>
                  <div className="pricingPayLink">
                    <div className="pricingButton">Try for free</div>
                  </div>
                  <div className="pricingList">
                    <ul>
                    <li>500 minutes of audio per month</li>
                    <li>Up to 5 projects</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      </PricingRoot>)}
      <Copyright />
    </div>
  )
}

export default Introduction;

import React from 'react';
import { jsPDF } from "jspdf"
import JSZip from 'jszip';
import saveAs from 'file-saver';
import { Document, Packer, Paragraph, TextRun } from 'docx';
import useAuth from "../../hooks/useAuth";
import Button from '@mui/joy/Button';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Divider from '@mui/joy/Divider';
import {
  useNavigate,
  redirect
} from "react-router-dom";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2'
import TextField from '@mui/material/TextField';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import SettingsIcon from '@mui/icons-material/Settings';
import ClearIcon from '@mui/icons-material/Clear';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import Add from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';

import firebase from "firebase/compat/app";
import { doc, setDoc, getFirestore, addDoc, collection, getDocs, query, DocumentData, deleteDoc } from "firebase/firestore"; 
import logo from './logo.svg';
import '../../App.css';

import { useState, useEffect, useContext } from "react";
import StyledFirebaseAuth from '../../features/auth/StyledFirebaseAuth';
import {
  deleteProject,
  createProject,
  getProjects,
  writeUser,
  getProject,
  createChapter,
  getChapters,
  deleteChapter,
  editProjectTitle,
  getProjectTotalDoc
} from '../../app/api/server';
import { AddProject } from '../AddProject';
import AuthContext from "../../Auth/AuthContext";
import { db } from '../../firebase';
import UserContext from "../../UserContext";

// Initialize Firebase
import { firebaseConfig } from '../../config/firebaseconfig';
import { current } from '@reduxjs/toolkit';
const ThemeContext = React.createContext('light');

function List() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [content, setContent] = useState('placeholder');
  const [totaldoc, setTotalDoc] = useState('');
  const [projects, setProjects] = useState<any[]>([]);
  const [contentArray, setContentArray] = useState([]);
  const [projectAdding, setProjectAdding] = useState(false);
  const [addingChapter, setAddingChapter] = useState(false);
  const [currentChapters, setCurrentChapters] = useState<any[]>([]);
  const [currentChaptersProject, setCurrentChaptersProject] = useState('');
  const [showChapters, setShowChapters] = useState(false);
  const [editingTitle, setEditingTitle] = useState(false);
  const [loadingProjects, setLoadingProjects] = useState(true);
  const [exporting, setExporting] = useState(false);
  const [settingsIng, setSettingsIng] = useState(false);

  const navigate = useNavigate();
  const userid = useAuth();

  useEffect(() => {

    if (userid.user) {
      if (userid.user && userid.user.uid) {
        getProjects(userid.user.uid).then((projects) => {
          setLoadingProjects(false);
          return setProjects(projects as any)});
      }
    } else {
      // no user
    }
  }, [userid.user]);

  useEffect(() => {
    if (currentChapters.length === 0) {
      setAddingChapter(true);
    }
  }, [projects]);

  const fetchTotalDoc = async (location: any) =>  {
    if (userid.user && userid.user.uid) {
        const totaldocfound = await getProjectTotalDoc(location, userid?.user?.uid);
        let finaldoc = '';
        totaldocfound.forEach((el: any, i: any) => {
          finaldoc = finaldoc + `${el}\n`;
        });
        setTotalDoc(finaldoc as any);
        return finaldoc;
      return '';
    } else {
      return '';
    }
  }

  const breakAndInsert = async (project: any) => {
    const content = project.content;
    setContentArray(content);
    const projectDetails = project.project;
    let totaldoc2 = '';
    content.forEach((el:any, i:number) => {
      totaldoc2 = totaldoc2 + el.dat.content;
    });
    setTotalDoc(totaldoc2);
  };

  const exportText = async (id: any) => {

    setExporting(true);
    setAddingChapter(false);
    setShowChapters(false);
    setCurrentChaptersProject(id);
    setSettingsIng(false);
  }

  const exportFile = async(type:any, row: any) => {

    if (type === 'txt') {
      exportTextOld(row);
    }
    if (type === 'pdf') {
      exportPDF(row)
    }
    if (type === 'word') {
      // Documents contain sections, you can have multiple sections per document, go here to learn more about sections
      // This simple example will only contain one section
      let finaldoc = await fetchTotalDoc(row.id);
      const fileName = row.dat.name.replace(/[^A-Z0-9]/ig, "_") + Math.random().toString(36).substr(2) + '.docx';
      const doc = new Document({
        sections: [
            {
                properties: {},
                children: [
                    new Paragraph({
                        children: [
                            new TextRun(finaldoc),
                            
                        ],
                    }),
                ],
            },
        ],
      });

      Packer.toBlob(doc).then(blob => {
        saveAs(blob, fileName);
        console.log("Document created successfully");
      });
    }
    if (type === 'word2') {
      const fileName = row.dat.name.replace(/[^A-Z0-9]/ig, "_") + Math.random().toString(36).substr(2) + '.docx';
      let finaldoc = await fetchTotalDoc(row.id);
      const zip = new JSZip();
    const doc = `
      <!DOCTYPE html>
      <html>
        <head>
          <title>Word Document</title>
        </head>
        <body>
          <p>${finaldoc}</p>
        </body>
      </html>
    `;

    zip.file('word/document.xml', doc);
    const content = await zip.generateAsync({ type: 'blob' });
    saveAs(content, fileName);
    }
    setExporting(false);
  }

  const exportPDF = async(row: any) => {

    const fileName = row.dat.name.replace(/[^A-Z0-9]/ig, "_") + Math.random().toString(36).substr(2) + '.pdf';
    let finaldoc = await fetchTotalDoc(row.id);


   
      const doc = new jsPDF();
      const text = finaldoc; // Replace with your text
      const lineHeight = 10; // define line height for the text
      const maxLinesPerPage = 25; // define maximum number of lines per page
      const lines = doc.splitTextToSize(text, 180); // Split the text
  
      let lineCount = 0;
      for(let i = 0; i < lines.length; i++) {
        if(lineCount === maxLinesPerPage) {
          doc.addPage(); // Add a new page
          lineCount = 0; // Reset line count for the new page
        }
        doc.text(lines[i], 10, 10 + lineCount * lineHeight); // Adds the text to the document at position (10,10) with the line height
        lineCount++;
      }
  
      doc.save('document.pdf');
  }

  
  const exportTextOld = async (row: any) => {
    const exportGenericFile = (text: string, fileName: string) => {
      const aElement = document.createElement("a");
      aElement.href = "data:text/plain;charset=utf-8," + text;
      aElement.download = fileName;
      aElement.style.display = "none";
      document.body.appendChild(aElement);
      aElement.click();
      document.body.removeChild(aElement);
    }

    let finaldoc = await fetchTotalDoc(row.id);
    let exportData = finaldoc;
    const fileName = row.dat.name.replace(/[^A-Z0-9]/ig, "_") + Math.random().toString(36).substr(2) + '.txt';
    /*

    let exportData = totaldoc.replace(/<[^>]+>/g, '');
    const fileName = row.dat.name.replace(/[^A-Z0-9]/ig, "_") + Math.random().toString(36).substr(2) + '.txt';
    exportData = exportData.replace(/<p>/g,'<br>');
    exportData = exportData.replace(/<\/p>/g,'');
    exportData = exportData.replace(/<br>/g,'\n');
    */
    exportGenericFile(exportData, fileName)
  };

  const deleteSelectedChapter = (projectid: string, chapterId: string) => {
    if (confirm("Are you sure you want to delete this section?") && userid?.user?.uid) {
      deleteChapter(projectid, chapterId, userid?.user?.uid).then(() => {
        showContents(projectid);
      });
    }
  }
 
  const saveTitleEdit = async(e:any) => {
    e.preventDefault();
    editTitle();
    const title = e.target.currentProjectTitle.value;
    editProjectTitle(title, userid?.user?.uid, e.target.projectId.value).then(() => {
      if (userid.user && userid.user.uid) {
        getProjects(userid.user.uid, true).then((projects) => {
          return setProjects(projects as any)});
      }
    });
  }

  const showProjectSettings = (row:any) => {

    setSettingsIng(true);
    setCurrentChaptersProject(row);
    setExporting(false);
    setAddingChapter(false);
    setShowChapters(false);
  }

  const toggleEditingTitle = (id: string) => {
    setEditingTitle(true);

    setAddingChapter(false);
    setShowChapters(false);
    setCurrentChaptersProject(id);
  }

  const editTitle = () => {
    editingTitle ? setEditingTitle(false) : setEditingTitle(true);
  }

  const editProject = (id: any, chapterId: any) => {
    navigate(`/recording/${id}/${chapterId}`, {
     state: {
       id,
       chapterId,
     }
   });
   }

   const cancelEditingTitle = async () => {
    setEditingTitle(false);
  }

  const cancelEditingChapter= async () => {
    setAddingChapter(false);
  }

   const showContents = async (id: any) => {
   
    if (!showChapters || id !== currentChaptersProject) {
      const theseChapters = await getChapters(userid?.user?.uid, id);
      setCurrentChapters(theseChapters);
      setEditingTitle(false);
      setSettingsIng(false);
      setExporting(false);
      setCurrentChaptersProject(id);
      setShowChapters(true);
      setAddingChapter(false);
    } else {
      setShowChapters(false)
    }
   }

  const addChapter = (id: string) => {
    setEditingTitle(false);
    setSettingsIng(false);
    setExporting(false);
    setShowChapters(false);
    if (id === currentChaptersProject) {
      setAddingChapter(true);
    } else {
      
    //  addingChapter ? setAddingChapter(false) : setAddingChapter(true);
    }
    setCurrentChaptersProject(id);
  }
  
 const toggleProjectAdding = (direction: boolean) => {
 
  setProjectAdding(direction);
  }

  const chapterFormSubmit = async(e:any) => {
    e.preventDefault();
    if (userid && userid.user) {
      createChapter(e.target.chapterTitle.value, e.target.projectId.value, userid.user.uid).then((newchapter) => {
            // get chapters back
            showContents(e.target.projectId.value);
        });
    }
}

const userInfox = React.useContext(UserContext) as any;

  return (
    <div className="App">
      <ThemeContext.Provider value="dark">
{userInfox.userInfo?.subs?.project_count >= userInfox.userInfo?.subs?.projects_allowed && (
  <div style={{margin:20}}>You have reached the maximum number of projects allowed on your plan. Please <a href='/introduction#copyblock'>upgrade your plan</a> to create more projects.</div>  
)}
{(userInfox.userInfo?.subs?.project_count < userInfox.userInfo?.subs?.projects_allowed && projectAdding || (projects.length === 0) && !loadingProjects) && (
<AddProject setCurrentChaptersProject={setCurrentChaptersProject} toggleProjectAdding={toggleProjectAdding} setProjects={setProjects} />)}
<div className="add-header" style={{padding:10,fontWeight:'bold',backgroundColor:'#fcfd91',marginTop:'0px', textAlign: 'left',color:'#323d43'}}>
{userInfox.userInfo?.subs?.project_count < userInfox.userInfo?.subs?.projects_allowed && !projectAdding && (
<Button onClick={() => toggleProjectAdding(true)} style={{float:'right',position:'relative',top:'-8px'}}>New project<Add /></Button>
)}

List of projects
 
  </div>
  <TableContainer component={Paper}>
    {projects.length > 0 && (
      <Table aria-label="simple table" style={{border:2,borderCollapse: 'initial',backgroundColor:'#fdfdfd',borderRadius:20}} >
        <TableBody>
          {
          projects.map((row) => (
            <TableRow
              key={`${row.id}`}
            >
              <TableCell component="th" scope="row" style={{borderBottom:'1px solid #999999'}}>
              <div>
                {((currentChaptersProject !== row.id && editingTitle) || (editingTitle === false) || (currentChaptersProject !== row.id)) && (<div style={{fontSize:'1.2em',alignItems:'center'}}>
    
         
          <div onClick={() => showContents(row.id)} style={{flex: '0 0 auto', fontWeight:'bold',marginRight:'30px',verticalAlign:'center',paddingBottom:'10px'}}>{row.dat.name}</div>
          
                  
                </div>
       
             )}
               {editingTitle && currentChaptersProject === row.id && (<div className="titleEdit">
  <form method="post" onSubmit={saveTitleEdit}>
    Edit title:
  <TextField  defaultValue={row.dat.name} id="currentProjectTitle" style={{margin:'5px',padding:'0px',width:'100%',backgroundColor:'#ffffff',color:'#000000'}}></TextField>
  <div style={{display:'flex'}}>
    <div style={{flex: 1}}>
      <Button onClick={cancelEditingTitle} style={{border:'1px solid #aaaaaa'}} >
        CANCEL
      </Button>
    </div>
    <div style={{flex: 'auto'}}></div>
    <div>
      <Button type="submit" style={{border:'1px solid #aaaaaa'}} >
        SAVE
      </Button>
    </div>
  </div>
      <input type="hidden" id="projectId" value={row.id} />
      </form>
 
  </div>)}
{((!editingTitle) || (currentChaptersProject !== row.id) || (currentChapters.length === 0 && currentChaptersProject !== row.id)) && (
              <div>
             <Tooltip title="Edit project"><Button onClick={function() {
                showContents(row.id)
                // editProject(row.id);
              }} style={{backgroundColor: showChapters && row.id === currentChaptersProject ? '#eeeae7' : '#ffffff',border:'1px solid #aaaaaa', marginRight:'0.8em',color:'#222222'}}><EditIcon style={{color:'#4e5060'}} /></Button></Tooltip>  
              <Tooltip title="Add section"><Button onClick={() => addChapter(row.id)} color="primary" style={{backgroundColor:addingChapter && row.id === currentChaptersProject ? '#eeeae7' : '#ffffff',border:'1px solid #aaaaaa', marginRight:'0.8em',color:'#222222'}}>
              <NoteAddIcon style={{color:'#4e5060'}} />
              </Button></Tooltip>
              <Tooltip title="Export project"><Button onClick={function() {
                exportText(row.id);
              }} style={{backgroundColor: exporting && row.id === currentChaptersProject ? '#eeeae7' : '#ffffff',border:'1px solid #aaaaaa', marginRight:'0.8em',color:'#222222'}}><FileDownloadIcon style={{color:'#4e5060'}} /></Button></Tooltip>
               <Tooltip title="Export project"><Button onClick={function() {
                showProjectSettings(row.id);
              }} style={{backgroundColor: settingsIng && row.id === currentChaptersProject ? '#eeeae7' : '#ffffff',border:'1px solid #aaaaaa', marginRight:'0.8em',color:'#222222'}}><SettingsIcon style={{color:'#4e5060'}} /></Button></Tooltip>
              
              </div>)}
              </div>
              <div id="extraContent">

              {(!editingTitle && !addingChapter && !showChapters && exporting && currentChaptersProject === row.id) && (
  
  <div className="exporterOptions" style={{borderRadius:'10px',border:'1px solid #dddddd',backgroundColor:'#f9f7f5'}}>
    <div style={{fontSize:'1.2em',margin:'10px',fontWeight:'bold'}}>Choose a file format to export to:</div>
  <div style={{margin:'1em'}}>
    <Button onClick={() => exportFile('pdf', row)}>Export as PDF</Button>
    <br /><br />
    <Button onClick={() => exportFile('word', row)}>Export as Word</Button>
    <br /><br />
    <Button onClick={() => exportFile('txt', row)}>Export as Plain Text</Button>
    <br /><br />
  </div>
  </div>
)}

{(!editingTitle && !exporting && !showChapters && settingsIng && currentChaptersProject === row.id) && (
  <div className="exporterOptions" style={{borderRadius:'10px',border:'1px solid #dddddd',backgroundColor:'#f9f7f5'}}>
    <div style={{fontSize:'1.2em',margin:'10px',fontWeight:'bold'}}>Rename or delete your project here:</div>
    <div style={{margin:'1em'}}>
      <div>
                    <Button onClick={function() {
                      toggleEditingTitle(row.id)
                    //  showContents(row.id)
                    }} color="primary" style={{marginBottom:'10px',backgroundColor:'#cccccc',color:'#222222'}}>{/*<EditIcon style={{color:'#4e7a65'}} />*/}Rename</Button>
           
           <Button onClick={function() {
                      exportText(row.id)
                    //  showContents(row.id)
                    }} color="primary" style={{marginLeft:'10px',marginBottom:'10px',backgroundColor:'#cccccc',color:'#222222'}}>{/*<EditIcon style={{color:'#4e7a65'}} />*/}Export</Button>
                    <Button onClick={function() {


                      if (confirm("Are you sure you want to delete this project?") && userid?.user?.uid) {
                        deleteProject(row.id, userid?.user?.uid, userInfox.userInfo.subs?.project_count)
                        .then((id) => {
                     
                          if (userid && userid.user) {
                     
                              getProjects(userid.user.uid).then((projects) => {
                                  setProjects(projects as any);
                                  // toggleProjectAdding();
                                  // props.setCurrentChaptersProject(id);
                                  // setCollapsed(false);
                                  userInfox.setUserInfo({
                                    ...userInfox.userInfo,
                                    subs: {
                                      ...userInfox.userInfo.subs,
                                      project_count: userInfox.userInfo.subs?.project_count - 1
                                    }
                                  });
                              });
                          }
                      });
                      }
                    //  showContents(row.id)
                    }} color="primary" style={{marginLeft:'10px',marginBottom:'10px',backgroundColor:'#cccccc',color:'#ad0000'}}>{/*<EditIcon style={{color:'#4e7a65'}} />*/}Delete</Button>
                  </div>

      <br /><br />
    
      <br /><br />
    </div>
  </div>
)}




              {((exporting && currentChapters.length === 0 && currentChaptersProject === row.id ) || (addingChapter && currentChaptersProject === row.id)) && !exporting && (
  <div>
     <form method="post" onSubmit={chapterFormSubmit}>
     <div className="chapterList" style={{borderRadius:'10px',border:'1px solid #dddddd',backgroundColor:'#f9f7f5'}}>
  {currentChapters.length > 0 && (<div style={{fontSize:'1.2em',margin:'10px',fontWeight:'bold'}}>Add a section:</div>)}
  {currentChapters.length === 0 && (
      <div style={{fontWeight:'bold',margin:'10px',fontSize:'1.2em'}}>First add a section to begin your journey:</div>
    )}
    <TextField id="chapterTitle" placeholder={currentChaptersProject === 'prose' ? 'chapter 1' : 'act one'} style={{margin:'0px',padding:'5px',backgroundColor:'#ffffff',color:'#000000',width:'95%'}}></TextField>
  </div>
  <div style={{display:'flex'}}>
    <div style={{flex: 1}}>
      <Button onClick={cancelEditingChapter} style={{border:'1px solid #aaaaaa'}} >
        CANCEL
      </Button>
    </div>
    <div style={{flex: 'auto'}}></div>
    <div>
      <Button type="submit" style={{border:'1px solid #aaaaaa'}} >
        SAVE
      </Button>
    </div>
  </div>
  <input type="hidden" id="projectId" value={row.id} />
  </form>
  </div>
)}






{(!editingTitle && !addingChapter && showChapters) && currentChaptersProject === row.id && currentChapters.length > 0 && (
  
  <div className="chapterList" style={{borderRadius:'10px',border:'1px solid #dddddd',backgroundColor:'#f9f7f5'}}>
    <div style={{fontSize:'1.2em',margin:'10px',fontWeight:'bold'}}>Choose section to edit:</div>
  <div>
    { currentChapters.map((chapRow:any) => (
        <div onClick={() => editProject(row.id, chapRow.id)} className="chapterListItem" key={chapRow.id} style={{display:'flex',padding:'5px',borderRadius:'5px',border:'1px solid #999999',margin:'10px'}}>
          <div style={{flex:'1',fontSize:'1.2em',position:'relative',left:'10px',top:'8px'}}><a>{chapRow.dat.name}</a></div>
          <div style={{flex:'0 0 auto'}}><Button style={{padding:0}}onClick={() => { deleteSelectedChapter(row.id, chapRow.id)}}><ClearIcon /></Button></div>
          </div>
      ))
    }
  </div>
  </div>
)}

              </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      )}
      {projects.length === 0 && !loadingProjects && (
        <div style={{margin:20}}>No projects created yet</div>
      )}
    </TableContainer>


</ThemeContext.Provider>
 </div>
  );
}

export default List;

import * as React from 'react';
import { useState, useEffect, useContext, useRef } from "react";
import useAuth from "../hooks/useAuth";
import Grid from '@mui/joy/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import FeedItem from './FeedItem';
import { getProjects, getFeedBlocks } from '../app/api/server';

export default function Feed({...props}) {

    const userid = useAuth();

  const editSection = (item: any) => {
    props.editSection(item);
  }

  const deleteSection = (item:any) => {
    props.deleteSection(item);
  }
  const redoSection = (item:any) => {
    props.redoSection(item);
  }

  const setCurrentlyEditinger = (id: any) => {
    console.log('close the open ones',id);
    setCurrentlyEditing(id);
  }

  const [currentlyEditing, setCurrentlyEditing] = useState(null);

  const insertSection = (direction:any, ordering: number, item:any) => {
    props.insertSection(direction, ordering, item);
    // props.redoSection(item);
  //  console.log('insertFn',position);
  }

  return (
    <div>
      <Table aria-label="basic table" style={{border:'#cccccc',backgroundColor:'#f2f2f2',fontFamily:props.project.species === 'screenplay' ? 'Courier' : 'Garamond'}}>
      {/*  <thead>
           <tr>
                <th style={{ width: '80%' }}>CONTENT</th>
                <th>ACTIONS</th>
            </tr>
        </thead>
  */}
        <tbody>
    {
     
    props.feedItems.map((item:any, index:number) => {


     const ending = ( props.feedItems.length - 1 === index) ? true : false;
      return (
   
        <FeedItem currentlyEditing={currentlyEditing} setCurrentlyEditinger={(id: any) => { setCurrentlyEditinger(id) } } setFeedItems={props.setFeedItems} user={props.user} project={props.project} index={index} insertSection={(direction: string, ordering: number, item:any) => { insertSection(direction, ordering, item) } } key={item.id} item={item} ending={ending} editSection={() => { editSection(item) }} deleteSection={() => { deleteSection(item) }} redoSection={() => { redoSection(item)}} />
    )
    }) 
}

</tbody>
    </Table>
    </div>
  );
}



import { doc, deleteDoc, updateDoc, setDoc, getFirestore, addDoc, collection, getDocs, orderBy, query, DocumentData, getDoc, where } from "firebase/firestore"; 
import { useState, useEffect, useContext, createContext } from "react";
import firebase from "firebase/compat/app";
import { db } from "../../firebase";
import { updateSubscriptionsAndAllowances } from "./server_fb";


const getLastUpdated = async (userid: string, projectid: string, contenttype: string) => {
    if (!navigator.onLine) {
        return true;
    }
    if (contenttype === 'projects') {
        projectid = 'defaultproject';
    }
    const docRef = doc(db, `users/${userid}/cachestamps/${projectid}`);
    const docSnap = await getDoc(docRef);
    const data = docSnap.data();
    const cacheAvailable = 'caches' in self;
    if (cacheAvailable) {
        const cache = await caches.open('sw-loca-ch');
        const cachedResponse = await cache.match(`/t-loca-${contenttype}-ft.json`);
        if (cachedResponse && data) {
            const lastUpdated = await cachedResponse.json();
            if (data[contenttype] > lastUpdated) {
                return false;
            }
        } 
    }
    return true;
}

const logLastUpdate = async (userid: string, projectid: string, contenttype: string) => {
    let docRef;
    if (contenttype === 'projects') {
        docRef = doc(db, `users/${userid}/cachestamps/defaultproject/`);
    } else {
        docRef = doc(db, `users/${userid}/cachestamps/${projectid}`);
    }
    setDoc(docRef, {
        [contenttype]: Date.now()
    }, {merge:true});

    const cacheAvailable = 'caches' in self;
    if (cacheAvailable) {
        const cache = await caches.open('sw-loca-ch');
        cache.put(`/t-loca-${contenttype}-ft.json`, new Response(Date.now().toString()));
    }
}

export const getUserInfo = async (userid: string, stripeId: string) => {
    if (!stripeId) {

        // TODO: handle error or get a new Stripe ID for the user
        // return;
      }
      try {
    // const response = await fetch(`http://localhost:5001/api/usage`, {
           const response = await fetch(`https://fictory-server.onrender.com/api/usage`, { 
            method: 'POST',
            headers: {
            // Accept: 'application.json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              customerId: userid,
              stripeId: stripeId,
          }),
          });
        // Ensure the fetch was successful
        if (!response.ok) {
   
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        let result = await response.json();
        const subs = await updateSubscriptionsAndAllowances(userid, result);

        result.subs = subs;
        result.value = {stripeId: stripeId};
        return result; 
    } catch (e) {
  
    }
}

export const deleteProject = async (id: string, userid: any, projectCount: number) => {
    await clearProjectCache(id);
    const q = query(collection(db, `users/${userid}/projects/${id}/characters`));
    const querySnapshot = await getDocs(q);
        
    const deleteOps:any = [];

    querySnapshot.forEach((doc) => {
    deleteOps.push(deleteDoc(doc.ref));      
    });

    const q2 = query(collection(db, `users/${userid}/projects/${id}/chapters`));
    const querySnapshot2 = await getDocs(q);
        
    const deleteOps2:any = [];

    querySnapshot2.forEach((doc) => {
    deleteOps2.push(deleteDoc(doc.ref));      
    });

    Promise.all(deleteOps).then(() => console.log('documents deleted'))
    const docRef = doc(db, `users/${userid}/projects`, id);
    // delete the characters for a project
    await deleteDoc(docRef);
    updateUserProjectCount(userid, projectCount - 1);
    await logLastUpdate(userid, docRef.id, 'projects');
}

export const updateProject = async (id: string, name: string, species: string, userid: any) => {
    const docRef = doc(db, `users/${userid}/projects`, id);
    await updateDoc(docRef, {
        name,
        species,
        lastUpdated: Date.now()
    });
    await clearProjectCache(id);
    await logLastUpdate(userid, id, 'projects');
}

export const createProject = async (name: string, species: string, userid: any, projectCount: number) => {
    const docRef = await addDoc(collection(db, `users/${userid}/projects`), {
        name,
        species,
        lastUpdated: Date.now()
    });
    
    updateUserProjectCount(userid, projectCount + 1);
    await logLastUpdate(userid, docRef.id, 'projects');
    // add to project
    return addProjectCache(docRef.id, name, species);
}

export const updateUserProjectCount = async (userid: any, amount: number) => {
    const docRef = doc(db, `user_allowances/${userid}`);
    await updateDoc(docRef, {
        project_count: amount
    });
    // update context...
}


export const addProjectCache = async (project: string, name: string, species: string) => {
    const cacheAvailable = 'caches' in self;
    if (cacheAvailable) {
        const cache = await caches.open('sw-p-ch');
        const cachedResponse = await cache.match('/t-p-ft.json');
        if (cachedResponse) {
            const jsonic = await cachedResponse.json();
            if (!jsonic.collection.projects) {
                jsonic.collection.projects = [];
            }
            jsonic.collection['projects'].push({id: project, dat: { name: name, species: species}});
       //     jsonic.collection[userid] = ({id: project, dat: { name: name, species: species}});
            cache.put('/t-p-ft.json', new Response(JSON.stringify({
                collection: jsonic.collection
            })));
            //return jsonic.collection;
            return project;
        }
    }
}

export const addChapterCache = async (project: string, name: string, chapterid: string) => {
    const cacheAvailable = 'caches' in self;
    if (cacheAvailable) {
        const cache = await caches.open('sw-ch-ch');
        const cachedResponse = await cache.match('/t-ch-ft.json');
        if (cachedResponse) {
            const jsonic = await cachedResponse.json();
            if (jsonic.collection[project]) {
            jsonic.collection[project].push({ id: chapterid, dat: { name: name } });
            } else {

            }
            const ty = ({
                collection: jsonic.collection
            });
            cache.put('/t-ch-ft.json', new Response(JSON.stringify({
                collection: jsonic.collection,
                banana: 5
            })));
        }
    }
}

export const createChapter = async (name: string, location: string, userid: any) => {
    const docRef = await addDoc(collection(db, `users/${userid}/projects/${location}/chapters/`), {
        name,
        lastUpdated: Date.now()
    });
    await addChapterCache(location, name, docRef.id);
    await logLastUpdate(userid, location, 'chapters');
    return docRef.id;
}

export const updateChapter = async (id: string, name: string, userid: any) => {
    const docRef = doc(db, `users/${userid}/projects/${id}/chapters/`, id);
    await updateDoc(docRef, {
        name,
        lastUpdated: Date.now()
    });
    await logLastUpdate(userid, id, 'chapters');
    await clearChapterCache(id);
}

export const deleteChapter = async (id: string, chapterId: string, userid: any) => {
    const docRef = doc(db, `users/${userid}/projects/${id}/chapters/`, chapterId);
    await deleteDoc(docRef);
    await logLastUpdate(userid, id, 'chapters');
    await clearChapterCache(id);
}

const clearProjectCache = async (id: string) => {
    const cacheAvailable = 'caches' in self;
    if (cacheAvailable) {
        const cache = await caches.open('sw-p-ch');
        const cachedResponse = await cache.match('/t-p-ft.json');
        if (cachedResponse) {
            const jsonic = await cachedResponse.json();
            jsonic.collection['projects'] = [];
            cache.put('/t-p-ft.json', new Response(JSON.stringify({
                collection: jsonic.collection
            })));
        }
     }
      //  cache.delete('/t-p-ft.json');
}

const clearCharacterCache = async (projectid: string) => {
    const cacheAvailable = 'caches' in self;
    if (cacheAvailable) {
        const cache = await caches.open('sw-charac-ch');
        const cachedResponse = await cache.match('/t-charac-ft.json');
        if (cachedResponse) {
            const jsonic = await cachedResponse.json();
            // find key wih id 
            delete jsonic.collection[projectid];
            cache.put('/t-charac-ft.json', new Response(JSON.stringify({
                collection: jsonic.collection
            })));
        }
    }
}

const clearChapterCache = async (id: string) => {
    const cacheAvailable = 'caches' in self;
    if (cacheAvailable) {
        const cache = await caches.open('sw-ch-ch');
        const cachedResponse = await cache.match('/t-ch-ft.json');
        if (cachedResponse) {
            const jsonic = await cachedResponse.json();
           delete jsonic.collection[id];
           cache.put('/t-ch-ft.json', new Response(JSON.stringify({
            collection: jsonic.collection
         })))
        }
    }
}

export const getCachedProjects = async (userid:any) => {
    const lastUpdated = await getLastUpdated(userid, 'defaultproject', 'projects');
    if (!lastUpdated) { return false };

    const cacheAvailable = 'caches' in self;
    if (cacheAvailable) {
        const cache = await caches.open('sw-p-ch');
        const cachedResponse = await cache.match('/t-p-ft.json');
        if (cachedResponse) {
            const json = await cachedResponse.json();
            if (json.collection.projects.length === 0) {
                return false;
            }

            return json.collection.projects;
        } else {
            return false;
        }
    }
}

export const getCachedProject = async (userid:any, projectid: string) => {
    const lastUpdated = await getLastUpdated(userid, 'defaultproject', 'projects');
    if (!lastUpdated) { return false };
    const cacheAvailable = 'caches' in self;
    if (cacheAvailable) {
        const cache = await caches.open('sw-pi-ch');
        const cachedResponse = await cache.match('/t-pi-ft.json');
        if (cachedResponse) {
            const json = await cachedResponse.json();
            if (!json.collection[projectid]) {
                return false;
            }
        // const chapters = json.collection['chapters'];
            
            return { project: json.collection[projectid], content: [] };

        } else {
            return false;
        }
    }
}

export const getCachedChapters = async (projectid:any, userid: string) => {
    const lastUpdated = await getLastUpdated(userid, 'projectid', 'chapters');
    if (!lastUpdated) { return false };
    
    const cacheAvailable = 'caches' in self;
    if (cacheAvailable) {
        const cache = await caches.open('sw-ch-ch');
        const cachedResponse = await cache.match('/t-ch-ft.json');
        if (cachedResponse) {
            const json = await cachedResponse.json();
            if (json.collection[projectid]) {
                return json.collection[projectid];
            } else {
                return false;
            }
        } else {
            return false;
        }
    }
}

export const getProjects = async (userid:any, fresh:boolean = false) => {

    let projobj: DocumentData[string] = {};
    let projarray: DocumentData[string] = [];
    const gotCached = await getCachedProjects(userid);

    if (!gotCached || fresh) {
        const q = collection(db, `users/${userid}/projects`);
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
     
            projobj[doc.id] = doc.data();
            projarray.push({ id: doc.id, dat: doc.data() });

        });
      
        // write to cache
        const cacheAvailable = 'caches' in self;
        if (cacheAvailable) {
            const cache = await caches.open('sw-p-ch');
            const cachedResponse = await cache.match('/t-fb-ft.json');
            if (cachedResponse) {
                const jsonic = await cachedResponse.json();
                jsonic.collection.projects = projarray;
                cache.put('/t-p-ft.json', new Response(JSON.stringify({
                    jsonic
                })));
                return jsonic.collection;
            } else {
                cache.put('/t-p-ft.json', new Response(JSON.stringify({collection: { projects: projarray }})));       
            }
        }
        // for each 
        return projarray;
    } else {
        return gotCached;
    }
}

export const getProject = async(location:any, userid: any) => {
    const gotCached = await getCachedProject(userid, location);
    if (!gotCached) {
      const docRef = doc(db, `users/${userid}/projects`, location);
      const docSnap = await getDoc(docRef);
      const chapters = await getChapters(userid, location);
      const content =  docSnap.data();
      if (content) {
        content.chapters = chapters;
        }
      const cacheAvailable = 'caches' in self;
        if (cacheAvailable) {
            const cache = await caches.open('sw-pi-ch');
            cache.put('/t-pi-ft.json', new Response(JSON.stringify({ collection: { [location]: content } })));
        }
            return { project: content, content: [] };
        } else {

            return { project: gotCached.project, content: [] } ;
        }
    
  }

  export const getProjectTotalDoc = async(projectid:any, userid: any) => {
    const items: DocumentData[] = [];
    const chapters = await getChapters(userid, projectid);
    for (const chapter of chapters) {
        const feedBlocks = await getFeedBlocks(userid,projectid,chapter.id);
        feedBlocks.forEach((el: {
            dat: any; type: string; character: { recordingExtraContext: { name: any; }; }; content: any; 
},i: any) => {
            if (el.dat.recordingExtraContext.type === 'character') {
                items.push(el.dat.recordingExtraContext.name);
            }
            if (el.dat.recordingExtraContext.type === 'action') {
                // items.push(el.character.recordingExtraContext.name);
            }
            items.push(el.dat.content);
        });
    }
    return items;
}

export const getCachedFeedBlocks = async (userid:any, projectid: string, chapterid: string) => {
    const lastUpdated = await getLastUpdated(userid, projectid, 'feedblocks');
    if (!lastUpdated) { return false };

    const cacheAvailable = 'caches' in self;
    if (cacheAvailable) {
        const cache = await caches.open('sw-fb-ch');
        const cachedResponse = await cache.match('/t-fb-ft.json');
        if (cachedResponse) {
            const json = await cachedResponse.json();
            // I need all the ones for a certain chapter...

            if (json.collection[projectid] && json.collection[projectid][chapterid]) {
                return json.collection[projectid][chapterid];
            } else {
                return false; 
            }
        } else {
            return false;
        }
    }
}


export const getFeedBlocks = async (userid:any, projectid: any, chapterid?: any, fresh: boolean = true) => {
    let projarray: DocumentData[] = [];
 
    const gotCached = await getCachedFeedBlocks(userid, projectid, chapterid);
    if (!gotCached || fresh) {
        const q = query(collection(db, `users/${userid}/projects/${projectid}/content/`), where("chapter", "==", chapterid), orderBy("ordering"));
        //  const q = collection(db, `users/${userid}/projects/${projectid}/content/`);
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            projarray.push({ id: doc.id, dat: doc.data() });
        });

        // write to cache
        const cacheAvailable = 'caches' in self;
        if (cacheAvailable) {
            const cache = await caches.open('sw-fb-ch');
            if (cache) {
                const cachedResponse = await cache.match('/t-fb-ft.json');
                if (cachedResponse) {      
                    const json = await cachedResponse.json();
                    if (!json.collection[projectid]) {
                        json.collection[projectid] = {};
                    }
                    json.collection[projectid][chapterid] = projarray;
                    cache.put('/t-fb-ft.json', new Response(JSON.stringify({
                        collection: { [chapterid]: projarray }
                    })));
                  return json.collection[projectid][chapterid];  
                } else {
                    cache.put('/t-fb-ft.json', new Response(JSON.stringify({ collection: { [projectid]: projarray } })));
                  
                }
            } 
            cache.put('/t-fb-ft.json', new Response(JSON.stringify({ collection: { [projectid]: { [chapterid]: projarray } } })));
        }
        return projarray;
    } else {
        return gotCached;
    }
}

export const getChapters = async (userid:any, projectid: any) => {
 
    let chaparray: DocumentData[] = [];
    const gotCached = await getCachedChapters(projectid, userid);
  
    if (!gotCached) {
     
        const q = query(collection(db, `users/${userid}/projects/${projectid}/chapters/`));
        //  const q = collection(db, `users/${userid}/projects/${projectid}/content/`);
        const querySnapshot = await getDocs(q);
       
        querySnapshot.forEach((doc) => {
            chaparray.push({ id: doc.id, dat: doc.data() });
        });
        // write to cache

        const cacheAvailable = 'caches' in self;
        if (cacheAvailable) {
            const cache = await caches.open('sw-ch-ch');
            if (cache) {
           
                const cachedResponse = await cache.match('/t-ch-ft.json');
                if (cachedResponse) {
                  
                    const json = await cachedResponse.json();
                   
                    json.collection[projectid] = chaparray;
                  
                    cache.put('/t-ch-ft.json', new Response(JSON.stringify({
                        collection: json.collection
                    })));
                  return json.collection[projectid];  
                } else {
                    cache.put('/t-ch-ft.json', new Response(JSON.stringify({ collection: { [projectid]: chaparray } })));
                  
                }
            }       
            // cache.put('/t-ch-ft.json', new Response(JSON.stringify({
            //     collection: { [projectid]: chaparray }
            //  })))
        }
        return chaparray;
    } else {
        return gotCached;
    }
}

export const editProjectTitle = async (name: string, userid: any, projectid: string) => {
    const docRef = doc(db, `users/${userid}/projects/${projectid}`);
    await updateDoc(docRef, {
        name: name,
        lastUpdated: Date.now()
    });
     let docRefo = docRef.id;
     await clearProjectCache(projectid);
     await logLastUpdate(userid, projectid, 'projects');
     return {docRefo: docRefo};
 }
 


export const editFeedBlock = async (userid: any, projectid: any, block: any, id: string, reorderingnumber:number, chapter: string, amountUsed?: number, userInfo?: any, setUserInfo?: any) => {

    const docRef = doc(db, `users/${userid}/projects/${projectid}/content/${id}`);
   if (reorderingnumber) {
    await updateDoc(docRef, {
        content: block,
        ordering: reorderingnumber,
        lastUpdated: Date.now()
    });
   } else {
        await updateDoc(docRef, {
            content: block,
            lastUpdated: Date.now()
        });
    }
    let docRefo = docRef.id;
    const blocks = await getFeedBlocks(userid, projectid, chapter, true);

         // JMUsage: add minutes to used minutes
         const usedSeconds = amountUsed;

         const docRefUsage = doc(db, `user_allowances/${userid}`);
         const docSnapUsage = await getDoc(docRefUsage);
         const dataUsage = docSnapUsage.data();
         if (dataUsage && usedSeconds) {
         const newAllowances = {
             current_end: dataUsage.current_end,
             current_start: dataUsage.current_start,
             minutes_count: dataUsage.minutes_count + (usedSeconds / 60), // minutes used
             plan: dataUsage.plan,
             project_count: dataUsage.project_count,
             minutes_allowed: dataUsage.minutes_allowed,
             projects_allowed: dataUsage.projects_allowed,
             usage_expires: dataUsage.usage_expires,
         };
         await updateDoc(docRefUsage, newAllowances);
         //JM and now reflect this in the app...


        const updatedUserInfo ={
            ...userInfo.userInfo,
            subs: {
                ...userInfo.userInfo.subs,
                minutes_count: newAllowances.minutes_count
            }
        }; 

        setUserInfo(updatedUserInfo); 
     }
         // END JMUsage
    await logLastUpdate(userid, projectid, 'feedblocks');
    return {feed: blocks, docRefo: docRefo};
}

export const saveFeedBlock = async (userid: any, projectid: any, block: any, ordering: number, recordingExtraContext: any, reordering: any, chapter?: any, amountUsed?: number,  userInfo?: any, setUserInfo?: any) => {
    if (!recordingExtraContext) {
        recordingExtraContext = {name:'',type:'',value:''};
    }

    let insertedOrderingShouldBe = ordering + 1;

    if (reordering.ordering) { 
        const array = await getFeedBlocks(userid, projectid, chapter, true);
        let insertBelow = true;
        if (array !== true) {

            if (reordering.direction === 'above') {
                // find the item above
                let index = array.findIndex((obj: { [x: string]: { [x: string]: any; }; }) => obj['dat']['ordering'] === reordering.ordering);
                if (index === -1) {
                   // nothing to do, just add at bottom as normal
                } else {
                    if (index === -1) {
                        // nothing to do, just add at bottom as normal
                    }  else {
                        let previousItem = array[index];
                        if (index !== 0) {
                            previousItem = array[index - 1];
                        }
                        let subChunk = array.slice(index);
                        let previousOrdering = previousItem.dat.ordering;
                        insertedOrderingShouldBe = previousOrdering + 1;
                        let newOrderingVal = insertedOrderingShouldBe;
                        subChunk.forEach((b: any) => {
                            newOrderingVal ++;
                            const docRef = doc(db, `users/${userid}/projects/${projectid}/content/${b.id}`);
                            updateDoc(docRef, {
                                ordering:  newOrderingVal,
                                chapter,
                                lastUpdated: Date.now()
                            });
                        });
                    }
                }
            }

            if (reordering.direction === 'below') {
                // find the item above
                let index = array.findIndex((obj: { [x: string]: { [x: string]: any; }; }) => obj['dat']['ordering'] === reordering.ordering);
                if (index === -1) {
                   // nothing to do, just add at bottom as normal
                } else {
                    if (index === -1) {
                        // nothing to do, just add at bottom as normal
                    } else {
                        let nextItem = array[index + 1];
                        let subChunk = array.slice(index + 1);

                        insertedOrderingShouldBe = array[index].dat.ordering + 1;

                        let newOrderingVal = insertedOrderingShouldBe;
                        subChunk.forEach((b: any) => {
                            newOrderingVal ++;
                            const docRef = doc(db, `users/${userid}/projects/${projectid}/content/${b.id}`);
                            updateDoc(docRef, {
                                ordering:  newOrderingVal,
                                chapter,
                                lastUpdated: Date.now()
                            });
                        });
                    }
                }
            }
        }
    }

    // ADDING A NEW BLOCK
    const docRef = await addDoc(collection(db, `users/${userid}/projects/${projectid}/content/`), {
        content: block,
        ordering: insertedOrderingShouldBe,
        recordingExtraContext,
        chapter,
        lastUpdated: Date.now()
    });
    let docRefo = null;
    docRefo = docRef.id;

    const fresh = true;
    const blocks = await getFeedBlocks(userid, projectid, chapter, fresh);

    const usedSeconds = amountUsed;

    const docRefUsage = doc(db, `user_allowances/${userid}`);
    const docSnapUsage = await getDoc(docRefUsage);
    const dataUsage = docSnapUsage.data();
    if (dataUsage && usedSeconds) {
                // JMUsage: add minutes to used minutes

    const newAllowances = {
        current_end: dataUsage.current_end,
        current_start: dataUsage.current_start,
        minutes_count: dataUsage.minutes_count + (usedSeconds / 60), // minutes used
        plan: dataUsage.plan,
        project_count: dataUsage.project_count,
        minutes_allowed: dataUsage.minutes_allowed,
        projects_allowed: dataUsage.projects_allowed,
        usage_expires: dataUsage.usage_expires,
    };
    await updateDoc(docRefUsage, newAllowances);


    const updatedUserInfo ={
        ...userInfo.userInfo,
        subs: {
            ...userInfo.userInfo.subs,
            minutes_count: newAllowances.minutes_count
        }
    }; 

    setUserInfo(updatedUserInfo); 
}
    
    await logLastUpdate(userid, projectid, 'feedblocks');

    return {feed: blocks, docRefo: docRefo};
}

export const deleteFeedBlock = async (userid: any, projectid: any, id: string, chapter: string) => {

    const docRef = doc(db, `users/${userid}/projects/${projectid}/content/`, id);
    await deleteDoc(docRef);
    const blocks = await getFeedBlocks(userid, projectid, chapter, true);
    await logLastUpdate(userid, projectid, 'feedblocks');
    return blocks;
}

// characters
export const deleteCharacter = async (id: string, userid: any, projectid: any) => {

    const docRef = doc(db, `users/${userid}/projects/${projectid}/characters/`, id);
    await deleteDoc(docRef);
    await clearCharacterCache(projectid);
    await logLastUpdate(userid, projectid, 'characters');
}

export const updateCharacter = async (id: string, name: string, userid: any, projectid: string) => {

    const docRef = doc(db, `users/${userid}/projects/${projectid}/characters/`, id);
    await updateDoc(docRef, {
        name,
        lastUpdated: Date.now()
    });
    await clearCharacterCache(projectid);
    await logLastUpdate(userid, projectid, 'characters');
}

export const createCharacter = async (name: string, userid: any, projectid: string, ) => {
    const docRef = await addDoc(collection(db, `users/${userid}/projects/${projectid}/characters`), {
        name,
        lastUpdated: Date.now(),
    });
    await logLastUpdate(userid, projectid, 'characters');
    return addCharacterCache(projectid, name, docRef.id)
}

export const getCachedCharacters = async (projectid:any, userid: string) => {
    const lastUpdated = await getLastUpdated(userid, projectid, 'characters');
    if (!lastUpdated) { return false };
    const cacheAvailable = 'caches' in self;
    if (cacheAvailable) {
        const cache = await caches.open('sw-charac-ch');
        const cachedResponse = await cache.match('/t-charac-ft.json');
        if (cachedResponse) {
            const json = await cachedResponse.json();
            if (json.collection[projectid]) {
                return json.collection[projectid];
            } else {
                return false;
            }
        } else {
        
            return false;
        }
    }
}

export const getCharacters = async ( userid: any, project:any) => {
    let chaparray: DocumentData[] = [];
    let projchar: DocumentData[string] = {};
 
    const gotCached = await getCachedCharacters(project, userid);
    if (!gotCached) {
         // get from cache or not.
       
        const q = collection(db, `users/${userid}/projects/${project}/characters`) 
        const projarray: DocumentData[] = [];
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            projarray.push({ id: doc.id, dat: doc.data() });
        });
        // cache these
        const newcharobj = {}

        projchar[project] = projarray;
        const cacheAvailable = 'caches' in self;
        if (cacheAvailable) {
            const cache = await caches.open('sw-charac-ch');
            const cachedResponse = await cache.match('/t-charac-ft.json');
            if (cachedResponse) {
                const jsonic = await cachedResponse.json();
                jsonic.collection[project] = projarray;
                cache.put('/t-charac-ft.json', new Response(JSON.stringify({
                    collection: jsonic.collection
                })));
                return projarray;
            } else {
                cache.put('/t-charac-ft.json', new Response(JSON.stringify({ collection: { [project]: projarray } })));
            }
        }
        return []
        //}
     //   return false;
    } else {
        return gotCached;
    }
}

export const addCharacterCache = async (project: string, name: string, id: string) => {
    const cacheAvailable = 'caches' in self;
    if (cacheAvailable) {
        const cache = await caches.open('sw-charac-ch');
        const cachedResponse = await cache.match('/t-charac-ft.json');
        if (cachedResponse) {
            const jsonic = await cachedResponse.json();
            jsonic.collection[project].push({ id, dat: { name, lastUpdated: Date.now() } });
            cache.put('/t-charac-ft.json', new Response(JSON.stringify({
                collection: jsonic.collection
            })));
            return jsonic.collection;
        } else {

        }
    }
    return false;
}



////////
export const logout = () => {
    localStorage.removeItem('Xuid');
    localStorage.removeItem('Xemail');
    caches.delete('sw-ch-ch');
    caches.delete('sw-p-ch');
    caches.delete('sw-pi-ch');
    caches.delete('sw-fb-ch');
    caches.delete('sw-charac-ch');
}


export const writeUser = async (name: string, email: string, uid: any) => {

    const dbRef = collection(db, "users");
    const data = {
        name,
        email,
        uid,
    }

    const docRef = doc(db, "users", uid);
    setDoc(docRef, data, {merge:true})
    .then(docRef => {
    })
    .catch(error => {
    })

    localStorage.setItem('Xuid', uid);
    localStorage.setItem('Xemail', email);
}

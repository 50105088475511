import React from 'react';
import Firebase from './firebase';

type FirebaseContextProps = {
    firebase: Firebase
    isOk: boolean
    data: string
  }
  
const FirebaseContext= React.createContext<Partial<FirebaseContextProps>>({})

export default FirebaseContext;
import React from 'react';
import {
  Navigate, useNavigate
} from "react-router-dom";
import firebase from "firebase/compat/app";
import { doc, setDoc, getFirestore, addDoc, collection } from "firebase/firestore"; 
import logo from './logo.svg';
import { ScreenRecording } from '../../features/audioTest/ScreenRecording';
import '../../App.css';

import { useState, useEffect } from "react";
import StyledFirebaseAuth from '../../features/auth/StyledFirebaseAuth';
import { writeUser } from '../../app/api/server';
import { firebaseConfig } from '../../config/firebaseconfig';
import getStripe from '../../lib/getStripe';

function Checkout() {

    async function handleCheckout() {
        const stripe = await getStripe();
        const { error } = await stripe.redirectToCheckout({
          lineItems: [
            {
              price: process.env.REACT_APP_NEXT_PUBLIC_STRIPE_PRICE_ID,
              quantity: 1,
            },
          ],
          mode: 'subscription',
          successUrl: `http://localhost:3000/list`,
          cancelUrl: `http://localhost:3000/pricing`,
          customerEmail: 'customer@email.com',
        });
        console.warn(error.message);
      }
    
      return (<button onClick={handleCheckout}>Checkout</button>);
    }


export default Checkout;
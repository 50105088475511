import React from 'react';
import { useState, useEffect } from "react";
import Checkout from '../Checkout/Checkout';
import '../../App.css';

function Pricing() {

  return (
    <div className="App">
      PRICING
        <div>
            <Checkout />
        </div>
   </div>
  )
}

export default Pricing;

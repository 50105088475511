import React from 'react';
import { useState, useEffect, useCallback, useMemo, useContext, useRef, useImperativeHandle, forwardRef } from "react";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { exportFile } from 'fs-browsers';
import { useWhisper } from '@chengsokdara/use-whisper'
import firebase from "firebase/compat/app";
import { doc, setDoc, getFirestore, addDoc, collection, updateDoc, getDoc, query, getDocs, DocumentData, deleteDoc, orderBy } from "firebase/firestore"; 

import logo from './logo.svg';
import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import Table from '@mui/material/Table';

import { ScreenRecording } from '../../features/audioTest/ScreenRecording';
import Editor from '../Editor';
import Feed from '../Feed';
import {
  useLocation,
  useNavigate,
  Navigate
} from "react-router-dom";
import '../../App.css';
import { AddCharacter } from '../AddCharacter';
import { CharacterList } from '../CharacterList';
import { CharacterBlock } from '../CharacterBlock';
import { ActionBlock } from '../ActionBlock';
import { getCharacters, getProject, saveFeedBlock, getFeedBlocks } from '../../app/api/server';
import useAuth from "../../hooks/useAuth";
import { initializeApp } from 'firebase/app';
import StyledFirebaseAuth from '../../features/auth/StyledFirebaseAuth';
import { firebaseConfig } from '../../config/firebaseconfig';

const ThemeContext = React.createContext('light');
import { db } from '../../firebase';




function Editing() {
 return <div />
}

export default Editing;


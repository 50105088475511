import * as React from 'react';
import { useEffect, useState } from "react";

import { Navigate, useOutlet, useNavigate } from "react-router-dom";
import { Popper } from '@mui/base/Popper';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { styled } from '@mui/joy/styles';
import Button from '@mui/joy/Button';
import MenuList from '@mui/joy/MenuList';
import MenuItem from '@mui/joy/MenuItem';
import { IconMap } from 'antd/lib/result';
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import IconButton from '@mui/material/IconButton';
import firebase from "firebase/compat/app";
import { logout as logoutpage } from '../app/api/server';
import getStripe from '../lib/getStripe';
import UserContext from "../UserContext";
import { endpoint } from "../config/firebaseconfig";


const Popup = styled(Popper)({
  zIndex: 1000,
});

function timestampToDate(timestamp) {
 
  // Create a new Date object from the timestamp
  const date = new Date(timestamp * 1000);

  // Get the day, month, and year from the date
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // +1 because months are 0-indexed
  const year = date.getFullYear();


  // Format the date as DD/MM/YYYY
  return `${day}/${month}/${year}`;
}

export default function MenuListComposition(props) {
  const intro = props.intro;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    if (anchorEl === event.currentTarget) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const userContext = React.useContext(UserContext);
  const [usageRemaining, setUsageRemaining] = useState('');
  const [projectsRemaining, setProjectsRemaining] = useState('');
  const [expiryDate, setExpiryDate] = useState('');

  // check usage
useEffect(() => {

  if (userContext.userInfo?.subs) {
    const minutesCount = userContext.userInfo.subs.minutes_count;
    // const minutesCount = 9000;
    const minutesAllowed = userContext.userInfo.subs.minutes_allowed;


    setProjectsRemaining(userContext.userInfo.subs.projects_allowed - userContext.userInfo.subs.project_count);
   
    if (userContext.userInfo.subs.usage_expires > (Date.now() / 1000) && minutesCount <  minutesAllowed) {
      const usage = (userContext.userInfo.subs.minutes_allowed - userContext.userInfo.subs.minutes_count);  
      setUsageRemaining(parseInt(usage));
    }
    if (userContext.userInfo.subs && userContext.userInfo.subs.usage_expires > (Date.now() / 1000) && minutesCount >=  minutesAllowed) {
     setUsageRemaining(0);
    }
    setExpiryDate(timestampToDate(userContext.userInfo.subs.usage_expires));
  }
}
,[userContext]);

  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      setAnchorEl(null);
    } else if (event.key === 'Escape') {
      anchorEl.focus();
      setAnchorEl(null);
    }
  };

  const logout = () => {
    firebase.auth().signOut().then(() => {
      // Sign-out successful.
      navigate('/introduction');
    }).catch((error) => {});
    logoutpage();
    navigate('/introduction');
  }

  const handleAccounts = () => {
fetch(`${endpoint}create-portal-session`, {

        headers: {
            "Content-Type": "application/json"
          },
        method: 'post',
        body: JSON.stringify({
            path: 'list',
            //session_id: ,
            customer_id: userContext.userInfo.value.stripeId,
        }),
    })
    .then(res => res.json())
    .then(
      (result) => {
        window.location.href = result.url;
       // setIsLoaded(true);
       // setItems(result);
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
        console.log(error);
      }
    )
}

const personFontSize = intro ? '1.5em' : '1em';


  return (
    <ClickAwayListener onClickAway={handleClose}>
    <div>
     
    <IconButton
        id="composition-button"
        aria-controls={open ? 'composition-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="outlined"
        color="neutral"
        onClick={handleClick}
        sx={{ borderRadius: 0 }}
    >
        <PersonIcon style={{fontSize:personFontSize,color:'#fdfdfd',color:'#666666',borderBottom:'1px solid #ffffff'}} />
      </IconButton>
   
      <Popup
        role={undefined}
        id="composition-menu"
        open={open}
        anchorEl={anchorEl}
        disablePortal
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 4],
            },
          },
        ]}
      >
          <MenuList
            variant="outlined"
            onKeyDown={handleListKeyDown}
            sx={{ boxShadow: 'md'}}
            style={{backgroundColor: "#fcfd91"}}
          >
           {/*<MenuItem onClick={handleClose}>Profile Settings</MenuItem>*/}
            <MenuItem onClick={handleAccounts}>Account and Billing</MenuItem>
            <MenuItem><a onClick={props.goToPricing}>Upgrade plan</a></MenuItem> 
            <MenuItem style={{borderTop:'2px solid #000000'}} onClick={logout}>Log-out</MenuItem>
            <UsageBox usageRemaining={usageRemaining} projectsRemaining={projectsRemaining} expiryDate={expiryDate} />
          </MenuList>
         
      </Popup>
    </div>
    </ClickAwayListener>
  );
}

const UsageBox = ({usageRemaining, projectsRemaining, expiryDate}) => {

  return (
    <div style={{padding: '10px', textAlign: 'center', fontSize: '0.8em', color: '#666666', borderTop: '1px solid #ffffff'}}>
      
      {usageRemaining !== '' && (
      <div>
        <p>Current plan</p>
        <p>{usageRemaining} minutes remaining</p>
        <p>{projectsRemaining} Projects remaining</p>
        <p>Minutes expire on...<br /> {expiryDate}</p>
      </div>)}
      <p><a href="/upgrade">Upgrade</a></p>
    </div>
  )
}

// @ts-nocheck
import React from 'react';
import { Row, Col, Badge } from "antd";
import { useState, useEffect, useCallback, useMemo, useContext, useRef, useImperativeHandle, forwardRef } from "react";
import Button from '@mui/joy/Button';

const SpeechRecognition = forwardRef(({ setTranscriptJM, stopRecordingNewModal, stoppedRecordingFromButton, setStoppedRecordingFromButton, recordingInProgress, setRecordingInProgress, setShouldListen, setProcessingRecording,   setSecondStageInProgress, emergencyStop, setEmergencyStop, recordingPaused, setRecordingPaused, project, characterOption, actionOption,setSpinnerWaiter, spinnerWaiter }, speechRef) => { 
  // so does not rerender,

  const SpeechRecognition =
  window.SpeechRecognition || window.webkitSpeechRecognition;

  const [recording, setRecording] = useState(false);
    const [paused, setPaused] = useState(false);
    const [transcript, setTranscript] = useState('');
    const recognitionRef = React.useRef(null);
    const [tempTranscript, setTempTranscript] = useState('');
    const tempTranscriptRef = useRef(transcript);
    const justResumedRef = useRef(false);
    const justPausedRef = useRef(false);
    const speechTimedOutRef = useRef(null);
    const [justResumed, setJustResumed] = useState(false);
    const timeoutIdRef = useRef(null);
    const userStoppedRef = useRef(false);
    const recordingInProgressRef = useRef(recordingInProgress);
    const [speaking, setSpeaking] = useState(false);
    const transcriptRef = useRef(transcript);
    const [wipChunksSoFar, setWipChunksSoFar] = useState([]);
    const wipChunksSoFarRef = useRef(wipChunksSoFar);
    const breakTimerRef = useRef(Date.now());
    const [dictatedHeight, setDictatedHeight] = useState(0);

    const transcriptAnchorRef = useRef(null);

    useEffect(() => {
      
      const div = transcriptAnchorRef.current;

   //   div.style.maxHeight =  document.getElementById('reworkedRecording').clientHeight + 'px';
      const divHeightInPixels = document.getElementById('reworkedRecording').clientHeight;
     // setDictatedHeight(divHeightInPixels);
      document.getElementById('reworkedRecording').style.maxHeight = divHeightInPixels + 'px';
      setDictatedHeight((divHeightInPixels - 100) + 'px');
      
   /* console.log('Div height in pixels:', divHeightInPixels);

    // Get the viewport height in pixels
    const viewportHeight = window.innerHeight;
    console.log('Viewport height in pixels:', viewportHeight);

    // Convert the div height to viewport units (vh)
    const divHeightInVh = (divHeightInPixels / viewportHeight) * 100;
    console.log('Div height in vh:', divHeightInVh);

    // Optionally, you can set this value back to the div
    div.style.maxHeight = divHeightInVh + 'vh';
*/
   // console.log('final height is ', divHeightInPixels)
    //console.log('DIX MAX = ', transcriptAnchorRef.current.style)
      // Function to scroll the div to the bottom
      const scrollToBottom = () => {
       
        if (div) {
          div.scrollTop = div.scrollHeight;
        }
      };
  
      // Call scrollToBottom whenever transcript changes
      scrollToBottom();
    }, [transcript]);
  


    const [wipChunk, setWipChunk] = useState('');
    const wipChunkRef = useRef(wipChunk);
    let timerRunning = false;

    const PAUSE_AMOUNT = 20000;

  
  // Update the ref each render so it always has the latest value
  recordingInProgressRef.current = recordingInProgress;
  transcriptRef.current = transcript;
  wipChunkRef.current = wipChunk;
  wipChunksSoFarRef.current = wipChunksSoFar;


  useEffect(() => {
    return () => {

     recordingInProgressRef.current = false;
     resetTimeout(false);
    }
  }, []);

  useEffect(() => {
    // Update the ref each time the component updates with the new transcript value
    tempTranscriptRef.current = tempTranscript;
  }, [tempTranscript]); // Depend on transcript so it updates with it

  useEffect(() => {
    // Update the ref each time the component updates with the new transcript value
    justResumedRef.current = justResumed;
  }, [justResumed]); // Depend on transcript so it updates with it

  useEffect(() => {   
    stopRecording();
  }, [emergencyStop]); // Depend on transcript so it updates with it

  useEffect(() => {
    // Update the ref each time the component updates with the new transcript value 
    justPausedRef.current = paused;
  }, [paused]); // Depend on transcript so it updates with it

  useEffect(() => {   
    stopRecording();
    resetTimeout(false);
  }, []); 

  // Function to reset the timeout
  const resetTimeout = (goagain = false) => {
    // Clear the existing timeout
    if (speechTimedOutRef.current) {
      clearTimeout(speechTimedOutRef.current);
    }
    // Set a new timeout
    if (goagain) {
      speechTimedOutRef.current = setTimeout(() => {

        // Handle timeout logic here
          if (!speaking) {
          stopRecording();
          clearTimeout(speechTimedOutRef.current);
          }
          // console.log('doing nothing but checking for still speaking');

      }, PAUSE_AMOUNT);
    }
  };
  
  useEffect(() => {
    recordingInProgressRef.current = recordingInProgress;
  
    if (SpeechRecognition) {
      recognitionRef.current = new SpeechRecognition();
      recognitionRef.current.continuous = false;
      recognitionRef.current.interimResults = true;
      recognitionRef.current.timeout = 10000;
  
      if (recordingInProgress) {
        startRecording();
      }
  
      let interimTranscript = "";
  
      // startRecording();
      // called every time/////
  
      recognitionRef.current.onaudiostart = (event) => {
        // console.log('Audio capturing started');
      };
  
      recognitionRef.current.onaudioend = (event) => {
        //   console.log('Audio capturing ended');
      };
  
      recognitionRef.current.onspeechstart = (event) => {
        //  console.log('SPEECH SARTED')
  
        //  if (!breakTimertRef.current) {
        // Start the timer
        // Stop the timer
        const endTime = new Date();
        const elapsedSeconds = Math.round(
          (endTime - breakTimerRef.current) / 1000,
        );
        if (project.species === "prose") {
          if (wipChunksSoFarRef.current.length > 0) {
            if (elapsedSeconds > 1 && elapsedSeconds <= 3) {
              // paragraph break
              wipChunksSoFarRef.current.push("\n    ");
            }
            if (elapsedSeconds > 3 && elapsedSeconds <= 10) {
              wipChunksSoFarRef.current.push("\n\n");
              // section
            }
          }
        }
  
        // document.getElementById('elapsedTime').textContent = `Elapsed Time: ${elapsedSeconds} seconds`;
        timerRunning = false;
        // this.textContent = 'Start';
        //}
        //this.textContent = 'Stop';
        setSpeaking(true);
        clearTimeout(speechTimedOutRef.current);
      };
  
      recognitionRef.current.onspeechend = (event) => {
        breakTimerRef.current = new Date();
        timerRunning = true;
      };
  
      recognitionRef.current.onend = (event) => {
        setWipChunksSoFar([...wipChunksSoFarRef.current, wipChunkRef.current]);
  
        if (
          !userStoppedRef.current &&
          recordingInProgressRef.current &&
          !justPausedRef.current
        ) {
          timeoutIdRef.current = setTimeout(() => {
            if (!userStoppedRef.current && recordingInProgressRef.current) {
              if (recordingInProgressRef.current) {
                recognitionRef.current.start();
              }
            }
            // and now start the silence check
            setSpeaking(false);
            resetTimeout(true);
          }, 0); // Restart after 5 seconds if not manually stopped
        }
      };
  
      recognitionRef.current.onstart = (event) => {
        //    console.log('recognition started');
      };
  
      recognitionRef.current.onnomatch = (event) => {
        console.log("no match");
      };
  
      recognitionRef.current.onerror = (event) => {
        //  console.log(event)
        // console.log('error');
      };
  
      recognitionRef.current.onpause = (event) => {
        console.log("paused");
      };
  
      recognitionRef.current.onresume = (event) => {
        console.log("resumed");
      };
  
      recognitionRef.current.soundstart = (event) => {
        //   console.log('Sound capturing started');
      };
  
      recognitionRef.current.onsoundend = (event) => {
        //    console.log('Sound capturing ended');
      };
  
      recognitionRef.current.onresult = (event) => {
        interimTranscript = transcript;
  
        const sofarLength = event.results.length;
  
        for (let i = 0; i <= event.resultIndex; i++) {
          const transcript2 = event.results[i][0].transcript;
          interimTranscript += "" + transcript2 + "";
        }
  
        if (justPausedRef.current) {
          setTranscript(tempTranscriptRef.current);
        } else {
          setWipChunk(interimTranscript);
          const newProcessedTranscript =
            wipChunksSoFarRef.current.join(" ") + " " + interimTranscript;
          setTranscript(newProcessedTranscript);
        }
      };
      //////////////////
    } else {
      console.error("SpeechRecognition is not supported in this browser");
    }
  
    if (!recordingInProgressRef.current) {
      recognitionRef.current.stop();
      clearTimeout(timeoutIdRef.current); // Clear any pending timeout when the component unmounts or updates
    }
  
    return () => {
      recognitionRef.current.stop();
      clearTimeout(timeoutIdRef.current); // Clear any pending timeout when the component unmounts or updates
      //  console.log('Recognition stopped (cleanup)', recordingInProgressRef.current);
    };
  }, [recordingInProgress, recordingInProgressRef.current]);
  // end of useEffect for recordingInProgress
  
  
    useEffect(() => {
  
      if (stoppedRecordingFromButton) {
        stopRecording();
        setStoppedRecordingFromButton(false);
      }
  
    }, [stoppedRecordingFromButton]);
  
    const startRecording = () => {
      clearTimeout(speechTimedOutRef.current);
          if (recognitionRef.current) {
        recognitionRef.current.start();
        setRecording(true);
        setPaused(false);
      }
    };
  
  
    useImperativeHandle(speechRef, () => ({
      stopRecording
    }));
    
  
    const stopRecording = (fromCharacter = false) => {

      if (recognitionRef.current) {
        recognitionRef.current.stop();
        setTranscriptJM(`.${transcriptRef.current}`);
        setRecording(false);
        setRecordingInProgress(false); 
        setPaused(false);
        
       /*
        if (spinnerWaiter && !fromCharacter) {
          setSpinnerWaiter(false);
        } else {
          if (characterOption || actionOption) {
            setSpinnerWaiter(true);
          }
        }
        */
      }
    };
  
    const pauseRecording = () => {
      clearTimeout(speechTimedOutRef.current);
      if (recognitionRef.current) {
        recognitionRef.current.stop();  
        setTempTranscript(transcript);
       
       // setTranscript(transcript);
        setPaused(true);
        setRecordingPaused(true);
      }
    };
    const resumeRecording = () => {
     
      setJustResumed(true);
      if (recognitionRef.current) {
        recognitionRef.current.start();
        setPaused(false);
        setRecordingPaused(false);

      }
    };
  
    const cancelRecording = () => {  
      if (recognitionRef.current) {
        recognitionRef.current.abort();  
       // stopRecordingNewModal();
      }
  
      setProcessingRecording(false);
      setPaused(false);
      setRecording(false);
      setRecordingInProgress(false);
      setSecondStageInProgress(false);
    
      //setIsRecognizing(false);
      setShouldListen(false);
      setTranscriptJM('');
     // setTotalTranscript('');
    }
    

    
    return (
      <div id="holdingDiv" style={{flex:1,height:'100%'}}>
        
        <div style={{whiteSpace:'pre-wrap',maxHeight:dictatedHeight}} className="transcript-container" ref={transcriptAnchorRef}>
     {transcript}  
        </div>
  
        <div style={{position:'fixed',bottom:0,width:'85%',paddingBottom:'0px',justifyContent:'space-between',display:'flex',padding:'20px' }}>
          <Button onClick={() => {
            cancelRecording();
          }}>CLOSE1</Button>
  
          <div style={{display:'flex'}}>
            {!paused && (
            <Button sx={{marginRight:'10px'}} onClick={pauseRecording} disabled={!recording || paused}>1PAUSE</Button>
            )}
            {paused && (
            <Button sx={{marginRight:'10px'}} onClick={resumeRecording}>RESUME</Button>
            )}
            <Button sx={{marginRight:'10px'}} onClick={stopRecording} disabled={!recording} >STOP</Button>
          </div>
        </div>
        </div>
    );
  });
  
  export default SpeechRecognition
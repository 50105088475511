import app from 'firebase/app';

const config = {
  /* apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  */
  apiKey: "AIzaSyAgIYLIpNuhznPxd8cs-osvIjulWXEg__E",
  authDomain: "speechwriter-2bfb1.firebaseapp.com",
  // databaseURL: "https://speechwriter-2bfb1-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "speechwriter-2bfb1",
  // storageBucket: "speechwriter-2bfb1.appspot.com",
  // messagingSenderId: "1040146784742",
  // appId: "1:1040146784742:web:1f81b458bac194a94eb627",
  // measurementId: "G-MS1NM53YCE"
};

class Firebase {
  constructor() {
  }
}

export default Firebase;
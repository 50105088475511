import React, {useState, useEffect, useMemo} from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useNavigate,
  Navigate,
} from "react-router-dom";
import '@fontsource/inter/800.css';
import '@fontsource/inter';
// mui
import { deepmerge } from '@mui/utils';
import { sha256 } from 'js-sha256';
import { experimental_extendTheme as extendMuiTheme } from '@mui/material/styles';
import colors from '@mui/joy/colors';
import {
  extendTheme as extendJoyTheme,
  CssVarsProvider,
  useColorScheme,
} from '@mui/joy/styles';
import Container from '@mui/material/Container';
import Button from '@mui/joy/Button';
import Sheet from '@mui/joy/Sheet';
import { spacing } from '@mui/system';
// firebase
import firebase from "firebase/compat/app";
import { doc, deleteDoc, updateDoc, setDoc, getFirestore, addDoc, onSnapshot, collection, getDocs, orderBy, query, DocumentData, getDoc, where } from "firebase/firestore"; 
// resources
import logo from './logo.svg';
import StyledFirebaseAuth from './features/auth/StyledFirebaseAuth';
// components
import './App.css';
import Login from './components/Login/Login';
import RecordingNew from './components/Recording/RecordingNew';
import Editing from './components/Editing/Editing';
import List from './components/List/List';
import Pricing from './components/Introduction/Pricing';
import { AuthProvider  } from "./Auth/AuthProvider";
import { ProtectedLayout } from "./app/ProtectedLayout";
import { HomeLayout } from "./app/HomeLayout";
import Introduction from "./components/Introduction/Introduction";
import { db } from './firebase';
import UserContext from "./UserContext";
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { logout as logoutpage, getUserInfo } from './app/api/server';
import { initializeApp } from 'firebase/app';
import { firebaseConfig } from "./config/firebaseconfig";

// You can put this to any file that's included in your tsconfig
declare module '@mui/joy/styles' {
  interface Palette {
    gradient: {
      primary: string;
    };
  }
}

// Theme

const ThemeContext = React.createContext('light');
const muiTheme = extendMuiTheme({
});
const joyTheme = extendJoyTheme({
  colorSchemes: {
    light: {
      palette: {
        primary: {
          // Credit:
          // https://github.com/tailwindlabs/tailwindcss/blob/master/src/public/colors.js
          50: '#fdf2f8',
          100: '#4d695c',
          200: '#323d43',
          300: '#4d695c',
          400: '#223344',
          500: '#323d43',
          600: '#485fc2',
          700: '#dddddd',
          800: '#9d174d',
          900: '#831843',
          solidBg: '#5c79f6',
          // solidBg: '#111111'
        },
      },
    },
    dark: {
      palette: {
        primary: {
          // Credit:
          // https://github.com/tailwindlabs/tailwindcss/blob/master/src/public/colors.js
          50: '#fdf2f8',
          100: '#fce7f3',
          200: '#fbcfe8',
          300: '#f9a8d4',
          400: '#f472b6',
          500: '#112233',
          600: '#db2777',
          700: '#cccccc',
          800: '#9d174d',
          900: '#831843',
          // Adjust the global variant tokens as you'd like.
          // The tokens should be the same for all color schemes.
          solidBg: 'var(--joy-palette-secondary-500)',
          solidActiveBg: 'var(--joy-palette-secondary-500)',
          outlinedBorder: 'var(--joy-palette-secondary-700)',
          outlinedColor: 'var(--joy-palette-secondary-600)',
          outlinedActiveBg: 'var(--joy-palette-secondary-900)',
          softColor: 'var(--joy-palette-secondary-500)',
          softBg: 'var(--joy-palette-secondary-900)',
          softActiveBg: 'var(--joy-palette-secondary-800)',
          plainColor: 'var(--joy-palette-secondary-500)',
          plainActiveBg: 'var(--joy-palette-secondary-900)',
        },
      },
    },
  },
  fontFamily: {

  },
});
// You can use your own `deepmerge` function.
// joyTheme will deeply merge to muiTheme.
const theme = deepmerge(muiTheme, joyTheme);
theme.spacing();

// main app begins
function App() {
  const [isVerified, setIsVerified] = useState(true);
  const [userInfo, setUserInfo] = useState({value:null});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);


  const [userId, setUserId] = useState('');
  const [stripeId, setStripeId] = useState('');

  // Define a function to fetch data
  const fetchData = async (userid: string, stripeId: string) => {
    // paid customers only?
    if (!stripeId) {
    //   return;
    }
    try {
      let result = await getUserInfo(userid, stripeId);
   
      // SET USER INFO IN LOCALSTORAGE HERE...
      setUserInfo(result);

    } catch (err:any) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const app = initializeApp(firebaseConfig);
  const firestore = getFirestore(app);

  const auth = getAuth(app);


  useEffect(() => {
    // This sets up the auth state listener
    const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
 
      if (user) {
        // User is signed in, set up Firestore listener
        const userRef = doc(firestore, 'users', user.uid);
        const unsubscribeFirestore = onSnapshot(userRef, (doc) => {
          if (doc.exists()) {

            fetchData(user.uid, doc.data().stripeId);
            // setStripeCustomerId(doc.data().customer_id);
            setLoading(false);
          } else {
            // Document does not exist yet, continue showing loading state
      
          }
        });

        // Unsubscribe from Firestore when the user logs out
        return unsubscribeFirestore;
      } else {

        // User is signed out
        setUserInfo({value:null});
        setLoading(false);
      //  setStripeCustomerId(null);
      }
    });
    
    // Cleanup auth listener when component unmounts
    return unsubscribeAuth;
  }, []);



  

  useEffect(() => {
    // Set up the onAuthStateChanged listener
    if (stripeId) {
      fetchData(userId, stripeId);
    }
  }, [stripeId]);

  // set currentUser state
  
useEffect(() => {
  if (userId) {
    const userRef = doc(db, "users", userId);
    const docSnap = getDoc(userRef).then((docSnapshot) => {
    const data = docSnapshot.data();

    setStripeId(data?.stripeId);
    })
  }
}, [userId])
  

  const logout = () => {
    firebase.auth().signOut().then(() => {
      // Sign-out successful.
   //   navigate('/introduction');
    }).catch((error) => {});
    logoutpage();
   // navigate('/introduction');
  }




  const checkPw = () => {
    // gets the current input value
    const answer = (document.getElementById("password") as HTMLInputElement).value;
    const hash = sha256(answer);
  
    if (hash === "aa26ef5420bb40106c04eeb9479c4469bddac48acc352e9872797ac72242160e") { 
      setIsVerified(true);
    } else {
      alert("Sorry, that's not it");
    }
  };

  const userContextValue = useMemo(
    () => ({ userInfo, setUserInfo, fetchData }), 
    [userInfo]
  );


  return (
    <>
    {isVerified ? (
    <Container disableGutters style={{backgroundColor:'#ffffff'}}>
      <Router>

        <AuthProvider>
        <UserContext.Provider value={ userContextValue }>
          <Routes>
            <Route path="/" element={<Introduction />} />
            <Route path="/introduction" element={<Introduction />} />
            <Route path="/upgrade" element={<Introduction />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route element={<HomeLayout />}>
              <Route path="/login" element={<Login />} />
            </Route>
            <Route path="/" element={<ProtectedLayout />}>
              <Route path="/" element={<List />} />
              <Route path="/list" element={<List />} />
              <Route path="/recording/:idx/:chapterId" element={<RecordingNew />} />
              <Route path="/editing/:id" element={<Editing />} />
            </Route>
          </Routes>
          </UserContext.Provider>
        </AuthProvider>
      </Router>
    </Container>
  )
      :
     (
      <div style={{backgroundColor:'#ffffff'}}>
      <form onSubmit={checkPw}>
       <input id="password" name="password" style={{color:'#000000'}} />
       <button>-----</button>
      </form>
      </div>
    )
  }
    </>
  );
}

export default App;
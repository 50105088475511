import React from 'react';
import { useState, useEffect, useContext } from "react";
import useAuth from "../hooks/useAuth";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/joy/Button';
import TextField from '@mui/material/TextField';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import FormLabel from '@mui/joy/FormLabel';
import Close from '@mui/icons-material/Close';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import { getProjects, createProject } from '../app/api/server';
import UserContext from "../UserContext";

import firebase from "firebase/compat/app";
import { doc, setDoc, getFirestore, addDoc, collection, getDocs, query, DocumentData, deleteDoc } from "firebase/firestore"; 
import { firebaseConfig } from '../config/firebaseconfig';
import { db } from '../firebase';

export function AddProject(props:any) {
    const setProjects = props.setProjects;
    const [collapsed, setCollapsed] = useState(true);
    const [speciesChosen, setSpeciesChosen] = useState('prose');



    const userInfox = React.useContext(UserContext) as any;

    const userid = useAuth();

    const handleSpecies = (event:any, newValue:any) => {
        setSpeciesChosen(newValue);
    }

    const formSubmit = async(e:any) => {
        e.preventDefault();
        
        if (userid && userid.user) {
            const projectCount = userInfox.userInfo.subs?.project_count;
            const projectsAllowed = userInfox.userInfo.subs?.projects_allowed;

            if (projectCount < projectsAllowed) {
                createProject(
                    e.target.title.value,
                    speciesChosen,
                    userid.user.uid,
                    (userInfox.userInfo.subs?.project_count)
                ).then((id) => {
                    if (userid && userid.user) {
                        getProjects(userid.user.uid).then((projects) => {
                            setProjects(projects as any);
                            props.setCurrentChaptersProject(id);
                            setCollapsed(false);
                            props.toggleProjectAdding();
                            const ux = {
                                ...userInfox.userInfo,
                                subs: {
                                    ...userInfox.userInfo.subs,
                                    project_count: userInfox.userInfo.subs?.project_count + 1
                                }
                            }
                            userInfox.setUserInfo({
                                ...userInfox.userInfo,
                                subs: {
                                    ...userInfox.userInfo.subs,
                                    project_count: userInfox.userInfo.subs?.project_count + 1
                                }
                            });  
                        });
                    }
                });
            }
        }
    }

    if (collapsed) {
        return (
            <div className="add-block" style={{backgroundColor:'#fdfdfd',borderRadius:10}}>
                <div className="add-header" style={{
                    padding:5,
                    paddingLeft:10,
                    backgroundColor:'#fcfd91',
                    textAlign: 'left',
                    color:'#323d43',
                    borderRadius:10
                }}>
                 {/*}   <Button onClick={() => props.toggleProjectAdding()} style={{float:'right',position:'relative',top:'-8px'}}><Close /></Button>
                    Add a new project:*/}
                </div>
                <div className="add-content" style={{borderRadius:10}}>
                    <form method="post" onSubmit={formSubmit}>
                    <Table aria-label="simple table">
                        <TableBody>
                        <TableRow>
                            <TableCell>I'm writing a...</TableCell>
                            <TableCell>
                            {speciesChosen === 'screenplay' ? (
                                <Button variant="solid" style={{marginRight:'1rem'}}>Screenplay</Button>
                                ) : (
                                <Button variant="outlined" onClick={() => setSpeciesChosen('screenplay')} style={{marginRight:'1rem'}}>Screenplay</Button>
                                )}
                            {speciesChosen === 'prose' ? (
                                <Button variant="solid">Novel</Button>
                                ) : (
                                <Button variant="outlined" onClick={() => setSpeciesChosen('prose')}>Novel</Button>
                                )}
                                       {/*} <Select
                                        placeholder="Species..."
                                        defaultValue="prose"
                                        slotProps={{
                                            button: {
                                              id: 'select-button',
                                              'aria-labelledby': 'select-label select-button',
                                            }
                                          }}
                                        
                                        id="species"
                                        onChange={(e, newValue) => {
                                           handleSpecies(e, newValue);
                                        }}  
                                        >
                                            <Option value="prose">prose</Option>
                                            <Option value="screenplay">screenplay</Option>
                                    </Select>*/}
                            </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Called...</TableCell>
                                <TableCell>
                                    <Grid>
                                    <Grid>
                                        <TextField name="title" placeholder="Type here" style={{width:'100%', backgroundColor:'#ffffff'}} />
                                    </Grid>
                                   
                                </Grid> 
                                </TableCell>
                            </TableRow> 
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell align="right"><Button type="submit">Create project</Button></TableCell>
                            </TableRow>
                            </TableBody>
                                </Table>  
                    </form>
                </div>
            </div>
        );
    } else {
        return (
            <div className="add-block">
                <div className="add-header" onClick={() => setCollapsed(true)}>
                <Button>Add project</Button>
                </div>
            </div>
        );
    }
}
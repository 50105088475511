
export const convertActions = (root = '', value = '') => {
  let text = root;
  let breakline = true;
  if (root === 'intday') {
    text = `INT. ${value.toUpperCase()} - DAY`;
    breakline = false;
  }
  if (root === 'extday') {
    text = `EXT. ${value.toUpperCase()} - DAY`;
    breakline = false;
  }
  if (root === 'intnight') {
    text = `INT. ${value.toUpperCase()} - NIGHT`;
    breakline = false;
  }
  if (root === 'extnight') {
    text = `EXT. ${value.toUpperCase()} - NIGHT`;
    breakline = false;
  }
  if (root === 'actionline') {
    breakline = false;
    text = value;
  }
  return {text:text, breakline:breakline};
}
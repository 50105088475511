import { Navigate, Outlet, Link } from "react-router-dom";
import useAuth from "../hooks/useAuth";

export const HomeLayout = () => {
  const { user } = useAuth();
  const userx = useAuth();

  if (userx.user) {
    return <Navigate to="/list" />;
  }

  return (
    <div>
      <Outlet />
    </div>
  )
};